import "./style.css";
import React, { useState, useEffect } from "react";
import executive from "../../assets/images/executive.svg";
import mail from "../../assets/images/mail.svg";
import { connect } from "react-redux";
import executivephone from "../../assets/images/executivephone.svg";
import Icon287 from "../../assets/images/Icon287.svg";
import building from "../../assets/images/building.svg";
import { initialState } from "../EditCompany/data";
import { getCompany } from "../../utils/apis/companiesApi";
import CompanySelector from "../../components/CompanySelector";
import Spinner from "../../components/Spinner";

function MyExecutive(props) {
  console.log(props);
  const [formData, setFormData] = useState({ ...initialState });
  const [executiveData, setExecutiveData] = useState(false);

  const getMyExecutive = async (id) => {
    try {
      setFormData({ ...initialState });
      const res = await getCompany(id);
      console.log("datos ejecutivo", res);
      setFormData({ ...res });
    } catch (error) {
      console.error(error);
      setFormData({ ...initialState });
    }
  };

  // useEffect(async () => {
  //   getMyExecutive(props.companies.defaultCompany.CompanyId);
  // }, [props.companies.defaultCompany]);

  return (
    <div className="screen__container__executive">
      {executiveData ? (
        <div className="e-company_main_container">
          <div className="container__company__selector">
            <p>
              Selecciona tu empresa para ver los datos del ejecutivo asociado:
            </p>
            <CompanySelector />
          </div>

          <div className="myexecutive">
            <div className="container__executive">
              <p>Datos de mi ejecutivo:</p>
            </div>
            <div className="info__executive">
              <p>
                {" "}
                <strong>{formData.ExecutiveName} </strong>
              </p>
              <p> {formData.ExecutiveEmail || <Spinner />} </p>
              <p>{formData.ExecutivePhone} </p>
            </div>
            <div className="img__executive">
              <img src={executive} alt="myexecutive"></img>
              <img className="message" src={mail} alt="myexecutive"></img>
              <img
                className="phone"
                src={executivephone}
                alt="myexecutive"
              ></img>
            </div>
            <div className="text__executive">
              <p>"Comprometidos en ayudar en todo el proceso"</p>
            </div>
            {/* <p className="text__final"><img src={Icon287} alt="questions"></img> Preguntas Frecuentes</p> */}
          </div>
        </div>
      ) : (
        <p className="not__company">USTED NO POSEE UN EJECUTIVO ASIGNADO</p>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  companies: state.companies,
});

export default connect(mapStateToProps)(MyExecutive);
