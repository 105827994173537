/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './styles.css'
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import WanakLogo from '../../assets/images/Iso_wanak.svg';
import MobileDropdownLink from './MobileDropdownLink';
import useScrollPosition from '../../utils/helpers/Hooks/useScrollPosition';
import Button from '../Button';
import CompanySelector from '../CompanySelector';
import CartIcon from './CartIcon';

function MobileHeader({
  menuOptions: {
    conocenosOptions, userOptions, userOptionsWithCompany,
  },
  user: { isAuth, dataUser: { ClientName } },
  companies: { companies, defaultCompany }, ...props
}) {
  const history = useHistory();
  // const [showMenu, setShowMenu] = useState(false);
  const scrollPosition = useScrollPosition();

  const navigate = (path) => {
    // setShowMenu(false);
    history.push(`${path}`);
  };

  const logout = () => {
    console.log('cerrando sesion');
    props.doLogout();
    props.resetCompanies();
    props.resetCart();
    // setShowMenu(false);
    history.push('/');
  };

  return (
    <header className={`header ${scrollPosition > 3 ? 'header__container--scrolled' : ''} `}>
      <div className="header__container">
        <div
          className="header__logo header__logo--mobile"
          onClick={() => {
            navigate('/');
          }}
          role="button"
          tabIndex={0}
        />
        <div className="header_left_container__mobile">
          <CartIcon style={{ fontSize: '27px', color: 'var(--primary-color)' }} />
          <div
            className="mobile_menu_toggler"
            role="button"
            tabIndex={0}
          >
            <div className="mobile_menu_toggler__hamburger" role="button" tabIndex="0">
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </div>
            <nav className="header__mobile-menu">
              <div className="mobile_menu_content_container">
                <div className="mobile_menu_icon"><img src={WanakLogo} alt="" /></div>
                {isAuth && (
                <div className="mobile_menu_logedUser">
                  <p className="mobile_menu_name">
                    Bienvenido
                    {' '}
                    { ClientName || '.'}
                  </p>
                  {companies.length > 0 && <CompanySelector />}
                  <MobileDropdownLink
                    link={{ label: 'Empresa' }}
                    // showMenu={showMenu}
                    // setShowMenu={setShowMenu}
                    options={companies.length > 0 ? userOptionsWithCompany : userOptions}
                    logout={logout}
                  />
                </div>
                )}
                <ul className="mobile-menu__nav__link-container">
                  <MobileDropdownLink link={{ label: 'Inicio', path: '/' }} />
                  <MobileDropdownLink link={{ label: 'Buscar Licitaciones', path: '/tenders' }} />
                  <MobileDropdownLink link={{ label: 'Cotizador', path: '/Quoter' }} />
                  <MobileDropdownLink link={{ label: 'Verificador', path: '/checkWarranty' }} />
                  <MobileDropdownLink options={conocenosOptions} link={{ label: 'Conócenos' }} />
                  {/* <MobileDropdownLink options={nuestrosProductosOptions} link={{ label: 'Nuestros Productos' }} /> */}

                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default MobileHeader;
