import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import InputSelect from "../../components/InputSelect";
import logoWanak from "../../assets/images/logoWanak.svg";
import { optionAddMember } from "./data";
import Form from "../../components/Form";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import Resumen from "./Resumen";
import { ProgressBar } from "react-bootstrap";

export default function AddMember({formData, setFormData, setPage}) {
  // const [formData, setFormData] = useState({ ...globalData });
  const [selectedOption, setSelectedOption] = useState(optionAddMember);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);

 
  const history = useHistory();


  const validateFields = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["memberName", "memberRut", "memberEmail", "memberAddress", "acceptCheck", "messageMember"],
      formData
    );
  
    setIncompleteFields(incompleteFieldsArray);
  };


  const handlerChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option)
    setFormData({
      ...formData,
      member: option

    })
  } 

  const returnFormData = () => {
    debugger;
    return formData
  }


  const handlerInput = (event) => {
    const name = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    localStorage.setItem("data addMember", JSON.stringify(formData));
  };

  const handlerCheck = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["memberName", "memberRut", "memberEmail", "memberAddress", "messageMember", "acceptCheck"],
      formData
    );
    if (incompleteFieldsArray.length > 0) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };

  useEffect(() => {
    validateButton();
  }, [formData]);

console.log('AL FINAL TENGO:', formData)

  return (
    <section className="register__container">
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard">
        <ProgressBar now={70} />
        </div>
      </div>
      <div className="container-forms-addmember">
        <div className="container-resumen">
        </div>
        <Resumen/>
        <div className="form__container__addmember">
        <div className="title-logo">
              <h2 className="addmember__title">AGREGA UN INTEGRANTE</h2>
              <img src={logoWanak}></img>
            </div>
        <Form
            // selectedOption={selectedOption}
            type="register"
            buttonValue="AGREGAR"
            formData={formData}
            submitButtonEnabled={!submitButtonEnabled}
            // onSubmit={formData}
            // goTo="/Validate"
          >
            <div className="register-form-principal-two">
              <div className="register-form__container">
                <div className="container-btn-data">
                  <InputSelect
                    name="member"
                    options={optionAddMember}
                    handlerChange={handlerChange}
                    defaultValue="Selecciona..."
                    error={incompleteFields.includes("member")}
                  />
                </div>
                <InputForm
                  type="text"
                  name="memberEmail"
                  title="Email"
                  placeholder="nombre@wanak.com"
                  value={formData.memberEmail}
                  handlerChange={handlerInput}
                  error={incompleteFields.includes("memberEmail")}
                />

                <InputForm
                  name="memberAddress"
                  title="Dirección"
                  value={formData.memberAddress}
                  handlerChange={handlerInput}
                  error={incompleteFields.includes("memberAddress")}
                />
                <InputForm
                  type="text"
                  name="memberName"
                  title="Nombre Completo "
                  placeholder="José Perez"
                  value={formData.memberName}
                  handlerChange={handlerInput}
                  error={incompleteFields.includes("memberName")}
                />

                <InputRut
                  formData={formData}
                  setFormData={setFormData}
                  name="memberRut"
                  title="RUT"
                  value={formData.memberRut}
                  handlerChange={handlerInput}
                  error={incompleteFields.includes("memberRut")}
                />

                <InputForm
                  type="text"
                  name="messageMember"
                  title="Relación con la empresa"
                  value={formData.messageMember}
                  handlerChange={handlerInput}
                  error={incompleteFields.includes("messageMember")}
                />
              </div>
            </div>
            <p className="acept-check">
              <input
                name="acceptCheck"
                value={formData.acceptCheck}
                type="checkbox"
                onClick={(e) => {
                  handlerCheck(e);
                  validateFields();
                }}
              ></input>
              Confirmo que la información ingresada es correcta
            </p>
            {/* <button className="btn-add-member" onClick={handlerClick}>AGREGAR</button> */}
            </Form>
        </div>

      </div>
      <div className="container__buttons__create">
      <div className="container-btn-later">
          <Form
            buttonValue="Continuar sin integrante"
            type="register"
            formData={formData}
            className="btn"
          ></Form>
        </div>
              <button
          //  disabled={page == FormTitles.length < 0 }
          type="button"
          className="btn__back__register"
        onClick={() => {setPage((e) => e -1)}}
        >Atrás</button>
        </div>
      <div className="container-btn-later">
        </div>
    </section>
  );
}
