import "./styles.css";
import InputForm from "../InputForm";
import InputSelect from "../InputSelect";
import React, { useState } from "react";
import { sendAnonymousSimulation } from "../../utils/apis/warrantiesApi";
import ModalLoading from "./ModalLoading";
import ModalSendRequest from "./ModalSendRequest";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function SimulationInitial({ incompleteFields, options, todaysDate }) {
  const initialState = {
    fullName: "",
    rut: "",
    phone: "",
    tenderAmountString: "",
    endDate: "",
    tenderWarrantyTarget: "",
  };

  const [data, setData] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const history = useHistory();

  console.log(data);
  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleClick = () => {
    history.goBack();
  };

  const simulationSimpleInitial = async () => {
    try {
      const res = await sendAnonymousSimulation(data);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setCloseModal(true);
      }, 3000);
    } catch (err) {
      console.error();
    }
  };

  return (
    <div>
      <div className="warranty__fields__flex1">
        {loading ? <ModalLoading /> : null}
        {closeModal ? <ModalSendRequest setCloseModal={setCloseModal} /> : null}
        <InputForm
          type="text"
          name="fullName"
          title="Nombre Completo"
          placeholder="Maria"
          value={data.fullName}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderAmountString")}
        />

        <InputForm
          type="text"
          name="rut"
          title="Rut:"
          placeholder="9.999.999-9"
          value={data.rut}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderAmountString")}
        />
        <InputForm
          type="text"
          name="phone"
          title="Teléfono"
          placeholder="+56 9 9999 9999"
          value={data.phone}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderAmountString")}
        />
      </div>
      <div className="warranty__fields__flex2">
        <InputForm
          type="text"
          name="tenderAmountString"
          title="Monto de garantía"
          // placeholder="1500000"
          value={data.tenderAmountString}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderAmountString")}
        />
        <InputForm
          title="Fecha Término"
          type="date"
          min={todaysDate}
          value={data.endDate || ""}
          name="endDate"
          handlerChange={inputHandler}
          error={incompleteFields.includes("endDate")}
        />
        <InputSelect
          name="tenderWarrantyTarget"
          title="Finalidad"
          value={data.tenderWarrantyTarget}
          options={options}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderWarrantyTarget")}
          defaultValue="Seleccione su finalidad..."
        />
      </div>

      <button
        type="button"
        onClick={() => handleClick()}
        className="button__gray mt-4 float-start"
      >
        Volver
      </button>
      <button
        type="button"
        onClick={() => simulationSimpleInitial()}
        className="button__blue mt-4 float-end"
      >
        Simular
      </button>
    </div>
  );
}

export default SimulationInitial;
