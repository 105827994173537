import React from 'react';
import Spinner from '../../Spinner';
import './styles.css';

function Loading() {
  return (
    <div className="step_notification_container">
      <h1
        className="step_notification_title"

      >
        Estamos creando un nuevo socio...
      </h1>

      <Spinner
        className="step_notification_spinner"
        styles={{
          borderLeftColor: '#304F97',
          width: 60,
          height: 60,
          marginTop: 50,
        }}
      />
    </div>
  );
}

export default Loading;
