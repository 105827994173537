import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { connect, useSelector } from "react-redux";
import PaymentModal from "../../components/PaymentModal";
import CartItem from "../../components/CartItem";
import CartTotal from "./CartTotal";
import Spinner from "../../components/Spinner";
import { sendToPay } from "../../utils/apis/warrantiesApi";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import cartShop from "../../assets/images/cartShop.svg"

const dataObject = {
  monto: 0,
  glosa: " ",
  productos: [
    {
      nombre_producto: "",
      descripcion_producto: "prueba",
      precio_producto: 0,
    },
  ],
  nom_pag: " ",
  mail_pag: " ",
  warrantiesIds: [0],
  companyUserId: 0,
};

function ShoppingCart({ shoppingCart, user, companies }) {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(shoppingCart.ShoppingCartTotal);
  const { loading: loadingCart } = useSelector((state) => state.shoppingCart);
  const [data, setData] = useState(dataObject);
  const history = useHistory();
  const { ClientId, Email, ClientName } = useSelector(
    ({ user: { dataUser } }) => dataUser
  );

  console.log(shoppingCart)
  const goToPayment = (total) => {
    if (shoppingCart.warranties[0].InsuranceCompanyId == -3) {
      data.companyUserId = ClientId;
      data.mail_pag = Email;
      data.nom_pag = ClientName;
      data.monto = totalPrice;
      data.glosa = shoppingCart.warranties[0].Glosa;
      data.productos[0].nombre_producto =
        shoppingCart.warranties[0].WarrantyType;
      data.productos[0].descripcion_producto =
        shoppingCart.warranties[0].WarrantyInfo;
      data.productos[0].precio_producto =
        shoppingCart.warranties[0].WarrantyCost;

      // console.log(shoppingCart);
      data.warrantiesIds = shoppingCart.warranties.map(
        (warranty, index) => warranty.WarrantyId
      );
      console.log(data);
      sendToPay(data).then((result) => {
        window.open(result.RedirectionFormUrl);
      });
    } else {
      setShowPaymentModal(true);
      setTotalPrice(total);
      setData();
    }
  };

  return !loadingCart ? (
    <div className="contenedor_carrito">
        <div className="cart_container">
          {shoppingCart.warranties.map((warranty, index) => (
            <CartItem
              item={warranty}
              key={index}
              company={companies.defaultCompany}
              user={user}
            />
          ))}
          {shoppingCart.warranties.length === 0 && (
          
            <div className="container__emptyCart">
              <div className="container__text__emptyCart">
              <h1 className="text__principal__shoppingCart" style={{ fontWeight: 400 }}>
                Aún no has agregado nada a tu carrito
              </h1>
              <p className="text__second__shoppingCart">
                ¡Puedes ir a nuestro cotizador para seleccionar el producto que
                necesitas!
              </p>
              <button className="button__white" onClick={() => history.push("/Quoter")}>Cotizador</button>
              </div>
              <div className="container__img__emptyCart">
                  <img src={cartShop} alt="cartShopping"></img>
              </div>
            </div>
          )}
        </div>
        {shoppingCart.warranties.length > 0 && (
          <CartTotal goToPayment={goToPayment} />
        )}
   
      {showPaymentModal && (
        <PaymentModal
          closeModal={() => setShowPaymentModal(false)}
          shoppingCart={shoppingCart}
          totalPrice={totalPrice}
          user={user}
        />
      )}
    </div>
  ) : (
    <LoadingScreen loadingText="Cargando Carrito..." />
  );
}
const mapStateToProps = ({ user, shoppingCart, companies }) => ({
  shoppingCart,
  user,
  companies,
});

export default connect(mapStateToProps)(ShoppingCart);
