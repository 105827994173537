import React, { useState } from 'react';
import './styles.css';
import { useSelector } from 'react-redux';
import ModalTemplate from '../ModalTemplate';
import KhipuLogo from '../../assets/images/Khipu-logo.svg';
import WebpayLogo from '../../assets/images/WebPay-logo.svg';
import { createNotification, createWebPayTransaction } from '../../utils/helpers/helpers';
import createKhipuTransactionApi from '../../utils/apis/khipuApi';
import LoadingScreen from '../Spinner/LoadingScreen';

function PaymentModal({
  closeModal,
  totalPrice,
}) {
  const warrantiesInShoppingCart = useSelector(({ shoppingCart }) => shoppingCart.warranties);
  const { ClientId, Company: { CompanyId } } = useSelector(({ user: { dataUser } }) => dataUser);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const webPayTransaction = async () => {
    const response = await createWebPayTransaction(ClientId, warrantiesInShoppingCart, totalPrice);
    if (response.message) {
      closeModal();
      createNotification('error', response.message, null, 3000);
    } else {
      const { RedirectionFormUrl, Token } = response;
      console.log(RedirectionFormUrl, Token);
      window.open(`${RedirectionFormUrl}?token_ws=${Token}`, '_self').focus();
    }
  };

  const khipuTransaction = async () => {
    const transactionData = {
      companyUserId: ClientId,
      warrantiesIds: warrantiesInShoppingCart.map(({ WarrantyId }) => WarrantyId),
      amount: totalPrice,
      companyId: CompanyId,
    };
    console.log('Khipu transaction data: ', transactionData);
    try {
      setLoadingPayment(true);
      const res = await createKhipuTransactionApi(transactionData);
      const { simplified_transfer_url: paymentUrl } = res;
      setLoadingPayment(false);
      window.open(`${paymentUrl}`, '_self').focus();
    } catch (error) {
      createNotification('error', 'Ha ocurrido un error al crear la transacción', null, 3000);
      console.log(error);
    }
    closeModal();
  };

  return (
    <div>
      <ModalTemplate closeModal={closeModal} title="Modo de pago" hideHeader>
        {!loadingPayment ? (
          <div className="paymentModal_container">
            <h1>Modos de Pago:</h1>
            <div className="payment_method_container" onClick={() => webPayTransaction()} role="button" tabIndex={0}>
              <img
                src={WebpayLogo}
                alt="Webpay logo"
              />

            </div>
            <div className="payment_method_container" onClick={() => khipuTransaction()} role="button" tabIndex={0}>
              <img
                src={KhipuLogo}
                alt="khipu logo"
              />

            </div>
          </div>
        ) : <LoadingScreen loadingText="Redireccionando a plataforma de pago" className="loading_payment_modal" />}
      </ModalTemplate>
    </div>
  );
}

export default PaymentModal;
