import graph from "../../../../assets/images/graph-webp.webp"
import info from "../../../../assets/images/info-webp.webp"

export const icons = [
    {
      image: <img src={graph} alt="piggy-bank" />,
      text: "Mantente al día de las últimas novedades del mercado",
      url: <a href="https://blog.wanak.com/contactanos/">Suscribirse</a>,
      header: "Newsletter"
    },
    {
      image: <img src={info} alt="piggy-bank" />,
      text: "Conoce nuestros artículos y aumenta tus conocimientos",
      url: <a href="http://blog.wanak.com/">Ir al blog</a>,
      header: "Blog"
    },
    
  ];