import { combineReducers } from 'redux';
import userReducer from './userReducer';
import companiesReducer from './companiesReducer';
import shoppingCartReducer from './shoppingCartReducer';

const reducer = combineReducers({
  user: userReducer,
  companies: companiesReducer,
  shoppingCart: shoppingCartReducer,
});

export default reducer;
