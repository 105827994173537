import "./styles.css";
import React, { useState } from "react";
import logoWanak from "../../assets/images/logoWanak.svg"


function ModalError({setError}) {


const closeModalError = () => {
setError(false)
}

    return(
<div className="container__modal__simulation">
<div className="modal__simulation">
    <div className="contenedor__modal">
        <div className="container__button__modalError">
        <button className="btn__close" onClick={closeModalError}>Cerrar</button>
        </div>

        <img src={logoWanak} alt="logo wanak"></img>
<h4>No se pudo procesar tu solicitud...</h4>
<p>¡Intentelo nuevamente!</p>
</div>
</div>
</div>

    )
}

export default  ModalError;