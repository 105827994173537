import React, { useEffect } from "react";
import InputForm from "../../../../components/InputForm";

const tenderWarrantyOptions = [
  {
    value: "-1",
    nameValue: "Seriedad de oferta",
    label: "Seriedad de oferta",
  },
  {
    value: "1",
    nameValue: "Fiel cumplimiento",
    label: "Fiel cumplimiento",
  },
  {
    value: "2",
    nameValue: "Correcta Ejecucion",
    label: "Correcta ejecución",
  },
  {
    value: "3",
    nameValue: "Fiel Cumplimiento y Correcta Ejecución",
    label: "Fiel cumplimiento y Correcta ejecución",
  },
  {
    value: "4",
    nameValue: "Correcta Ejecución y Buen Comportamiento de la Obra",
    label: "Correcta ejecución y Buen comportamiento de la obra",
  },
];

function CaseCotizacion({
  formData,
  setFormData,
  incompleteFields,
  commentTextArea,
}) {
  // eslint-disable-next-line no-unused-vars

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;    
    setFormData({
      ...formData,
      [key]: value,
    });
  };


  return (
    <div className="case__cotizacion_container">
      <div>
        <h3 className="input__title form_contact_second_title">
          Que finalidad necesitas?
        </h3>
        {incompleteFields.includes("warrantyTarget") && (
          <h4 className="incomplete_field_warning">
            Seleccione una finalidad para continuar*
          </h4>
        )}
      </div>

      <div className="case__cotizacion_container__radio">
        {tenderWarrantyOptions.map(({ value, label }, index) => (
          <label key={index} className="input__title">
            <input
              type="radio"
              name="warrantyTarget"
              value={label}
              onChange={(event) => inputHandler(event)}
              className="form-check-input"
            />
            {label}
          </label>
        ))}
      </div>
      <div className="case__cotizacion__container__input">
        <InputForm
          type="text"
          name="warrantyId"
          title="Id de Licitación:"
          placeholder="ej: 2369-2-LR22"
          value={formData.warrantyId || ""}
          handlerChange={inputHandler}
          error={incompleteFields.includes("warrantyId")}
        />
      </div>
      {commentTextArea("Necesita ayuda con una garantía...")}
    </div>
  );
}

export default CaseCotizacion;
