import axios from 'axios';

const URL_BASE = 'https://appcore-inge-api.azurewebsites.net/';
// const URL_BASE = 'https://localhost:44342/';
function requestBaseMethod(url, verb, data, token, urlBase = URL_BASE) {
  return new Promise((resolve, reject) => {
    axios({
      method: verb,
      url: `${urlBase}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Wanak': 'wanak',
        "Authorization": "Bearer "+ token
      },
      data: data || null,
    })
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}




export default requestBaseMethod;
