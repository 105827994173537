import React, { useState } from "react";
import "./styles.css";
import { connect, useSelector } from "react-redux";
import PasswordChange from "../../components/PasswordChange";
import process from "../../assets/images/process.svg";
import { useHistory } from "react-router-dom";
import { dataClient } from "../../utils/apis/clientUsersApi";
import { useEffect } from "react";
import ModalEditUser from "./ModalEditUser";
import { setUserEdit } from "../../store/actions/userActions";
import Spinner from "../../components/Spinner";
import edit from "../../assets/images/edit.svg";
import ModalClave from "./ModalClave";

function Profile(props) {
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const [ClaveSii, setClaveSii] = useState(false);
  const history = useHistory();

  const user = props.user.dataUser;

  const openModalSii = () => {
    setClaveSii(true);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const getData = async () => {
    try {
      const res = await dataClient(props.user.token);
      setFormData({ ...res });
      // setGetToken();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    getData();
  }, []);

  return (
    <section className="documents-screen screen__container_profile">
      <article className="profile_main_container">
        <section className="container__e-company_form_container">
          <div className="profile_form_container">
            <div className="data__user__profile">
              <p className="name__user">{user.ClientName}</p>
              <span className="edit__name__user" onClick={openModal}>
                <strong className="d-flex align-items-center justify-content-around">
                  Editar Perfil
                  <img src={edit}></img>
                </strong>
              </span>
            </div>
            <div className="container__data">
              <div className="text__dataUser">
                <p>
                  <strong>Rut: </strong>
                  {user.Rut}
                </p>
                <p>
                  <strong>Correo: </strong>
                  {user.Email}
                </p>
                <p>
                  <strong>Contacto: </strong>
                  {user.Phone}
                </p>
              </div>
              {/* <div className="profile__img__wanak"><img src={profilewanak} alt="profile" className="profile__img"></img></div> */}
              {!passwordChangeOpen ? (
                <div className="container__button__changeP">
                  <button
                    className="changePassword"
                    onClick={() => setPasswordChangeOpen(true)}
                  >
                    Cambiar contraseña
                  </button>
                </div>
              ) : (
                <div className="Modal__background__changePassword">
                  <div className="modal__change__password">
                    <h4>INGRESAR NUEVA CONTRASEÑA</h4>
                    <PasswordChange
                      className="modal__change__password"
                      userData={formData}
                      formData={formData}
                      setFormData={setFormData}
                      closeSection={() => setPasswordChangeOpen(false)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="container__saveData mt-4">
              <button
                type="button"
                onClick={openModalSii}
                className="button__profile__cotizador"
              >
                <p>Clave Tributaria</p>
              </button>
            </div>
          </div>
        </section>
        <section className="container__tenders">
          <div className="tenders__table">
            <div className="container__text__table">
              <p>Muy pronto...</p>
              <p>Nuevas licitaciones para tí</p>
            </div>
            <img src={process} alt="en proceso"></img>
          </div>
          <button
            className="button__profile__cotizador"
            onClick={() => history.push("/Quoter")}
          >
            <p>Ir al cotizador</p>
          </button>
        </section>
      </article>

      {showModal ? (
        <ModalEditUser
          setFormData={setFormData}
          formData={formData}
          setShowModal={setShowModal}
          props={props}
        />
      ) : (
        false
      )}
      {ClaveSii ? <ModalClave setClaveSii={setClaveSii} /> : false}
    </section>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  setUserEdit: (payload) => dispatch(setUserEdit(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
