import React, { useEffect, useState } from 'react';
import './styles.css';
import {
  faBuilding, faShoppingCart, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import CartIcon from './CartIcon';

function InfoDisplay({ companies: { companies }, userOptions, ...props}) {

  const [activeCompany, setActiveCompany] = useState(props.activeCompany);
  // const [dataUser, setDataUser ] = useState(props.user); 
  // const [name, setName] = useState(props.user.dataUser)
  const { dataUser } = props.user;
  const history = useHistory();
  const [width] = useWindowDimentions();


  const logout = () => {
    console.log('cerrando sesion');
    props.doLogout();
    props.resetCompanies();
    props.resetCart();
    history.push('/');
  };

  const navigate = (path) => {
    history.push(`${path}`);
  };

  useEffect(() => {
    setActiveCompany(props.activeCompany);
  }, [props.activeCompany]);

//   useEffect(() => {
// setName(props.user.dataUser);
//   }, [props.user.dataUser]);


  return (
    <div className="nav__menu">
      <div className="nav__menu__clientDisplay">
        {width > 1100 ? (
          <span className="nav__menu__client-name">
   {dataUser.ClientName}
          </span>
        )
          : (
            <FontAwesomeIcon
              icon={faUser}
              color="#0065FF"
              style={{ fontSize: '1.2rem' }}
            />
          )}
      </div>
      <CartIcon style={{ cursor: 'pointer' }} />
    </div>

  );
}

export default InfoDisplay
