export const dataOptions = {
    contactFormId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  
  export const initialOption = "Selecciona un seguro";
  
  export const options = [
    {
      value: "Instalación y equipos electrónicos",
      label: "Instalación y equipos electrónicos",
    },
    {
      value: "Equipo móvil",
      label: "Equipo móvil",
    },
  ];
  
  export const initialRequiredFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "message",
  ];
  