import React, { useState } from 'react';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import CompanySelector from '../../components/CompanySelector';
import WarrantiesTable from './warrantiesTable';
import ContractsTable from './contractsTable';

const documentsText = [
  {
    text: () => (
      <span>
        Visualiza tus contratos, garantías y verifica el estado en que se encuentran.
      </span>
    ),
  },
  {
    text: () => (
      <span>
        Si tienes documentos pendientes, dirígete al portal
        <span className="documents-screen__span--bold">DEC</span>
        {' '}
        para firmarlos o contrata a tu ejecutivo para que te ayude. Recuerda,
        son los socios de la empresa quienes deben firmar los contratos.
      </span>
    ),
  },
  {
    text: () => (
      <span>
        ¿Tienes dudas sobre el proceso de firma?. Revisa nuestro
        <span className="documents-screen__span--bold">Instructivo.</span>
      </span>
    ),
  },
];

function Documents({ companies }) {
  // const [changingCompany, setChangingCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(companies.defaultCompany);

  return (
    <section className="documents-screen screen__container">
      <div className="container__documents__all">
      <h1 className="documents-screen__title">
        Mis Documentos
      </h1>
      <ul className="documents-screen__list">
        {documentsText.map(({ text }, index) => (
          <li key={index}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#1251AE"
              style={{ marginRight: 10 }}
            />
            {text()}
          </li>
        ))}

      </ul>
      <div className="companySelector_container">
        <h2 className="screen__document__title">Elige tu compañía</h2>
        <CompanySelector setSelectedCompany={setSelectedCompany} />
      </div>
      <div className="documents-screen__table">
        <h2 className="documents-screen__table__title">
          Garantías
        </h2>
        <WarrantiesTable
          company={selectedCompany}
          // changingCompany={changingCompany}
          // setChangingCompany={setChangingCompany}
        />
      </div>
      <div className="documents-screen__table" style={{ marginTop: 30 }}>
        <h2 className="documents-screen__table__title">
          Contratos
        </h2>
        <ContractsTable
          company={selectedCompany}
          // loading={changingCompany}
          // setLoading={setChangingCompany}
        />
      </div>
      </div>
    </section>
  );
}

const mapStateToProps = ({ companies }) => ({
  companies,
});

export default connect(mapStateToProps)(Documents);
