import React from 'react';
import './styles.css';

const timelineItems = [
  {
    text: 'Asesoría comercial grupo GGEE / Grupo Security',
    year: '2014',
    img: null,
  },
  {
    text: 'Finanzas corporativas',
    year: '2015',
    img: null,
  },
  {
    text: 'Fondo de inversión SGR / Aportante y Gestor en sociedad con BTG Pactual',
    year: '2016',
    img: null,
  },
  {
    text: 'Fondo de inversión SGR / Aportante y Gestor en sociedad con Toesca AGF',
    year: '2017',
    img: null,
  },
  {
    text: 'InGe SAGR',
    year: '2020',
    img: null,
  },
  {
    text: 'InGe Corredores de Seguros Limitada',
    year: '2021',
    img: null,
  },
  {
    text: 'Wanak.com',
    year: '2022',
    img: null,
  },
];

function Timeline() {
  return (
    <div className="timeline_container">
      <div className="timeline_line" />
      {timelineItems.map((item, index) => (
        <div className={`timeline_item ${index % 2 === 0 ? 'left' : 'right'}`}>
          <div className="timeline_info">
            <h1>{item.text}</h1>
            {item.img && <img src={item.img} alt="" />}
          </div>
          <div className="timeline_year">
            <span>{item.year}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Timeline;
