import "./styles.css";
import React, { useState } from "react";
import { connect } from 'react-redux';
import InputForm from "../InputForm";
import { sendSimulation } from "../../utils/apis/warrantiesApi";
import { updateShoppingCart } from "../../utils/helpers/helpers";
import { createNotification } from "../../utils/helpers/helpers";
import { useHistory } from "react-router-dom";
import { addToCart } from '../../store/actions/shoppingCartActions';
import ModalErrorExecutive from "./ModalErrorExecutive";



function ModalSummary({ response, setSummary, dataUser, ...props}) {
  const [active, setActive] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [data, setData] = useState({});
  const history = useHistory();


  const numberFormat = new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'});

  const setTenderWarrantyTarget = (value) => {
    switch (value) {
      case '-1':
        return 'Seriedad de oferta';
      case '1':
        return 'Fiel cumplimiento';
      case '2':
        return 'Correcta ejecución';
      case '3':
        return 'Fiel cumplimiento y correcta ejecución';
      case '4':
        return 'Correcta ejecución y buen comportamiento de la obra';
      default:
        return ' ';
    }
  };
  const changeCheck = (e) => {
    const value = e.target.value;
    setActive(!active);
  }

  const sendSimulationWarranties = async () => {
    try {
      const warrantyTarget = setTenderWarrantyTarget(response.TenderWarrantyTarget);
      const request = {
        ...response,
        TenderWarrantyTarget: warrantyTarget,
        clientId: dataUser.ClientId
      };
      const res = await sendSimulation(request);
      setData(res)
      if (res.Message) {
        setModalError(true);
      } else if (res.ScoringState === 'Error') {
        setModalError(true);
      } else {
        await updateShoppingCart(dataUser.ClientId, props.addToCart);
        createNotification('success', 'Ir a pagar', 'Licitación agregada correctamente', 3000, () => history.push('/cart'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container__modal__summary">
      <div className="modal__simulation__summary">
{modalError ? <ModalErrorExecutive data={data}/> : null}
        <div className="contenedor__modal__summary">
          <p className="summary__text">RESUMEN</p> 
          <InputForm
            readOnly
            value={"ID de licitación:" + " " + response.TenderCode}
            className="input__form__summary"
          />
          <InputForm
            readOnly
            value={"Finalidad:" + " " + setTenderWarrantyTarget(response.TenderWarrantyTarget)}
            className="input__form__summary"
          />
          <InputForm
            readOnly
            value={"Monto:" + " " + numberFormat.format(response.TenderAmount)}
            className="input__form__summary"
          />
          <InputForm
            readOnly
            value={"Comisión:" + " " + numberFormat.format(response.WarrantyCost)}
            className="input__form__summary"
          />
          <InputForm
            readOnly
            value={"Glosa:" + " " + response.Glosa}
            className="input__form__summary"
          />
          <div className="accept-section">
              <input
                type="checkbox"
                value={active}
                onChange={changeCheck}
              ></input>
              <span>La información es <strong>correcta</strong></span>
          </div>
  {/* <p className="acept-check">
              <input
                name="acceptCheck"
                type="checkbox"
                value={valueCheck}
                onChange={changeCheck}
              ></input>
             La información es <strong>correcta?</strong>
            </p> */}
            <div className="container__button__checkbox">
            <button type="button" className="btn__checkbox"  disabled={!active} onClick={()=>sendSimulationWarranties()}
             >SOLICITAR</button>
            </div>
        </div>
      </div>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});

export default connect(null, mapDispatchToProps)( ModalSummary);