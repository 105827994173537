import React, { useState } from 'react';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import image from '../../assets/images/faqs.png';
import BannerFaqs from '../../components/BannerFaqs';

function Faqs() {
  const [value, setValue] = useState('');

  const search = () => {
    console.log('search', value);
  };
  return (
    <section className="faqs-screen">
      <h1>Preguntas Frecuentes</h1>
      <div>
        <figure>
          <img src={image} alt="faqs tenders" />
          <label>
            <span>Buscar</span>
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              color="#405FFB"
              onClick={search}
            />
          </label>
        </figure>
      </div>
      <BannerFaqs
        title="¿Lorem Ipsum is simply dummy text of the printing and typesetting industry?"
      />
    </section>
  );
}

export default Faqs;
