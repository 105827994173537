

  export const options = [{
    value: 'Socio',
    label: 'Socio'
},
{
    value: 'Representante Legal',
    label: 'Representante Legal'
},
{
    value: 'Otro',
    label: 'Otro'
}


]