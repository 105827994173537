import React, { useState } from "react";
import logo from "../../assets/images/logo_wanak.svg";
import validate from "../../assets/images/validate.svg";
import InputForm from "../../components/InputForm";
import Button from "../../components/Button";
import check from "../../assets/images/check.svg";
import { sendUserValidationEmail } from "../../utils/apis/clientUsersApi";
import "./styles.css";
import Resumen from "../AddMember/Resumen";
import { ProgressBar } from "react-bootstrap";

function ValidateEmail() {
  const [email, setEmail] = useState("");

  const handlerInput = (event) => {
    setEmail(event.target.value);
  };

  const sendEmail = () => {
    sendUserValidationEmail(email)
      .then((res) => {
        console.log(res);
        alert("El correo ha sido enviado con exito");
      })
      .catch((error) => {
        console.error(error);
        alert(
          "El correo no ha podido ser enviado. Intenta nuevamente más tarde."
        );
      });
  };

  return (
    <section className="container__validate__screen">
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard">
          <ProgressBar now={100} />
        </div>
      </div>
      <div className="validateEmail_container">
        <h1 className="validate__title">VERIFICA TU CUENTA</h1>
        <p className="text-validate-email">
          Revisa tu mail y verifica tu cuenta para
          <p>obtener el mejor servicio.</p>
        </p>

        <figure className="validate__image">
          <img src={validate} alt="security" />
        </figure>

        <Button
          type="button"
          value="Reenviar"
          color="#0065FF"
          clickHandler={sendEmail}
          className="btn-send-validate-email"
          // disabled={email === ""}
        />
        <p className="text-final-validate-email">No olvides revisar spam</p>
      </div>
    </section>
  );
}

export default ValidateEmail;
