import "./style.css";

import { icons } from "./data";

export default function Blog() {
  return (
    <section className="explain__container__blog">
      <div className="explain__title">
        <h1 className="title__main">Conoce más sobre nosotros...</h1>
      </div>
      <div className="explain__cards__container">
        {icons.map((icon, index) => (
          <div className="container__our__products" key={index}>
            <div className="container__title__our__products">{icon.header}</div>
            <div className="container__img__our__products">{icon.image}</div>
            {/* <button className="button__blue">{icon.text}</button> */}
            <button className="button__blue">{icon.url}</button>
          </div>
        ))}
      </div>
    </section>
  );
}
