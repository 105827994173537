import React from 'react';
import './styles.css';

function Spinner({ styles }) {
  return (
    <div className="spinner" style={styles ? { ...styles } : null} />
  );
}

export default Spinner;
