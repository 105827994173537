import React from 'react';
import './styles.css';
import Spinner from '../Spinner';

function SquareButton({
  value, type, clickHandler, styles, loading,
}) {
  return (
    <button
      className="button__blue align-self-end"
      type={type}
      onClick={clickHandler}
      style={{ ...styles }}
    >
      {loading ? <Spinner /> : value}
    </button>

  );
}

export default SquareButton;
