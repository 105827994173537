import React from "react";
import shoppingCart from "../../assets/images/shoppingCart.svg";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./styles.css";

function CartIcon({ style, user: { isAuth } }) {
  const history = useHistory();

  const { warranties: warrantiesInCart, loading: cartLoading } = useSelector(
    (state) => state.shoppingCart
  );
  return (
    <div>
      {isAuth ? (
        <div className="cartIcon_container">
          <img
            src={shoppingCart}
            alt="shoppingCart"
            onClick={() => history.push("/cart")}
          ></img>

          {!cartLoading && (
            <div className="cart_indicator">{warrantiesInCart.length}</div>
          )}
        </div>
      ) : null}
    </div>
  );
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(CartIcon);
