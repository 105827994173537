/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./styles.css";
import { connect } from "react-redux";
import { addToCart } from "../../../../store/actions/shoppingCartActions";
// icon svg
import { icons, ourProducts } from "./data";
import { useHistory } from "react-router-dom";
import points from "../../../../assets/images/points.svg";

function Explain({ user, companies }) {
  const { dataUser } = user;
  const [tender, setTender] = useState({});
  const [showModal, setShowModal] = useState(false);

  const closeBackdrop = (e) => {
    if (e.target.className === "backdrop_container") {
      console.log("backdrop clicked");
      setShowModal(false);
    }
  };

  return (
    <article className="explain__container">
      <section className="explain__title">
        <h2 className="title__main">Nuestros Productos</h2>
        <p>
          Cotiza en nuestra plataforma, solicitando y firmando de forma online,
          obteniendo información de resultados y un completo centro de usuario.
        </p>
      </section>
      <section className="explain__cards__container">
        {ourProducts.map((product, index) => (
          <div className="container__our__products" key={index}>
            <div className="container__title__our__products">
              {product.header}
            </div>
            <div className="container__img__our__products">{product.image}</div>
            <p className="text__our_products">{product.text}</p>
            <a className="button__blue" href={product.url}>
              Cotizar
            </a>
          </div>
        ))}
      </section>
      <section className="banner__newsletter">
        <div className="container__newSletter">
          <div className="mt-2">
            <h3>¿Te gustaría estar al día?</h3>
            <p>¡Suscríbete a nuestro newsletter y no te pierdas de nada!</p>
          </div>
          <a href="https://blog.wanak.com/contactanos/">
            <button className="button__white mb-4">Suscríbete</button>
          </a>
        </div>
      </section>
    </article>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});

export default connect(mapDispatchToProps)(Explain);
