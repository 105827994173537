import React, { useEffect, useState } from 'react';
import { checkIncompleteFields, getFormatedDate } from '../../../utils/helpers/helpers';
import InputForm from '../../InputForm';
import InputSelect from '../../InputSelect';
import SquareButton from '../../SquareButton';

function StepThree({
  warrantiesData,
  // eslint-disable-next-line no-unused-vars
  next,
  goBack,
  setWarrantiesData,
  tenderWarrantyOptions,
}) {
  const {
    tenderCode,
    tenderWarrantyTarget,
    glosa,
    startDate,
    endDate,
    tenderAmountString,
    tenderBuyerName,
    tenderBuyerRut,
    tenderBuyerAddress2,
    tenderbuyerAddress,
    tenderBuyerAddress3,
  } = warrantiesData;


  console.log(warrantiesData)
  const requiredFields = [
    'tenderWarrantyTarget', 'glosa', 'startDate', 'endDate', 'tenderAmountString',
  ];

  const [currentDate, setCurrentDate] = useState('');
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    /// /////////////////////////////////////////////////////
    // Primero se saca el input en el que se está escribiendo de los campos incompletos.
    /// /////////////////////////////////////////////////////
    setIncompleteFields(incompleteFields.filter((field) => field !== event.target.name));
    /// ///////////////////////////////////////////
    const key = event.target.name;
    const { value } = event.target;
    setWarrantiesData((data) => ({
      ...data,
      [key]: value,
    }));
  };

  const nextStep = () => {
    const incompleteFieldsArray = checkIncompleteFields(requiredFields, warrantiesData);
    setIncompleteFields(incompleteFieldsArray);

    if (incompleteFieldsArray.length === 0) {
      next();
    }
  };

  useEffect(() => {
    const { year, month, day } = getFormatedDate();
    setCurrentDate(`${year}-${month}-${day}`);
  }, []);

  return (
    <div className="step_container" style={{ paddingRight: '1em' }}>
      <div className="step_content">
        <h2
          className="step_content_title"
        >
          Garantía
        </h2>
        <InputForm
          type="text"
          name="tenderCode"
          title="Número de Licitación"
          value={tenderCode}
          readOnly
        />
        <InputForm
          type="text"
          name="tenderBuyerName"
          title="Nombre Empresa Mandante"
          value={tenderBuyerName}
          handlerChange={inputHandler}
          readOnly
        />
        <InputForm
          type="text"
          name="tenderBuyerRut"
          title="Rut Empresa Mandante"
          value={tenderBuyerRut}
          handlerChange={inputHandler}
          readOnly
        />
        <InputForm
          type="text"
          name="tenderBuyerAddress3"
          title="Región Empresa Mandante"
          value={tenderBuyerAddress3}
          handlerChange={inputHandler}
        />
        <InputForm
          type="text"
          name="tenderBuyerAddress2"
          title="Comuna Empresa Mandante"
          value={tenderBuyerAddress2}
          handlerChange={inputHandler}
        />
        <InputForm
          type="text"
          name="tenderbuyerAddress"
          title="Dirección Empresa Mandante"
          value={tenderbuyerAddress}
          handlerChange={inputHandler}
        />
        <InputForm
          type="text"
          name="glosa"
          title="Glosa"
          placeholder="Ingresar Glosa"
          value={glosa}
          handlerChange={inputHandler}
          error={incompleteFields.includes('glosa')}
        />
        <InputSelect
          name="tenderWarrantyTarget"
          title="Finalidad"
          value={tenderWarrantyTarget}
          options={tenderWarrantyOptions}
          defaultValue="Elija finalidad..."
          handlerChange={inputHandler}
          error={incompleteFields.includes('tenderWarrantyTarget')}
        />
        <InputForm
          type="number"
          name="tenderAmountString"
          title="Monto Garantía"
          value={tenderAmountString}
          handlerChange={inputHandler}
          error={incompleteFields.includes('tenderAmountString')}
        />
        <div className="step_content_dates">
          <InputForm
            type="date"
            name="startDate"
            title="Inicio"
            min={currentDate}
            value={startDate}
            handlerChange={inputHandler}
            error={incompleteFields.includes('startDate')}
          />
          <InputForm
            type="date"
            name="endDate"
            title="Fin"
            value={endDate}
            handlerChange={inputHandler}
            error={incompleteFields.includes('endDate')}
          />
        </div>
      </div>
      <div
        className="step_actions"
      >
        <SquareButton
          value="Atrás"
          type="text"
          clickHandler={() => goBack()}
          styles={{
            backgroundColor: 'transparent',
          }}
        />
        <SquareButton
          value="Siguiente"
          type="text"
          clickHandler={nextStep}
        />
      </div>
    </div>
  );
}

export default StepThree;
