import React, { useState } from "react";
import { Warranty } from "./Warranty";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SimpleSimulationSO from "./simpleSimulationSO";
import SimpleSimulationFC from "./simpleSimulationFC";
import SimpleSimulationCE from "./simpleSimulationCE";
import SimpleSimulationFCCE from "./simpleSimulationFCCE";
import SimpleSimulationCEBC from "./simpleSimulationCEBC";


 function SimulationSimple(){
    const [page, setPage] = useState(0)
    

    
    const viewsWarrantys = () => {
        if(page === 0){
            return <Warranty setPage={setPage}/>
        }else if(page === 1){
              return <SimpleSimulationSO  warrantyType={"-1"}/>
        }else if(page === 2){
            return <SimpleSimulationSO  warrantyType={"1"}/>
        }else if (page === 3){
            return <SimpleSimulationSO  warrantyType={"2"}/>
        }else if (page === 4){
            return <SimpleSimulationSO  warrantyType={"3"}/>
        }else if (page === 5){
            return <SimpleSimulationSO  warrantyType={"4"}/>
        }
    
    }
    return(<div className="container__views__warrantys">
        <div>{viewsWarrantys()}</div>
    </div>)
    }


    export default  SimulationSimple;