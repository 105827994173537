import React, { useState, useEffect } from "react";
import "./style.css";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import logow from "../../assets/images/logow.png";
import landing2 from "../../assets/images/landing2.png";
import {
  checkIncompleteFields,
  createNotification,
} from "../../utils/helpers/helpers";
import { sendQuotation } from "../../utils/apis/quotation";

const dataOptions = {
  firstName: "",
  lastName: "",
  rut: "",
  email: "",
  phone: "",
  message: "",
};
export const initialRequiredFields = [
  "firstName",
  "lastName",
  "rut",
  "email",
  "phone",
  "message",
];

export default function Landing() {
  const [formData, setFormData] = useState({ ...dataOptions });
  const [requiredFields, setRequiredFields] = useState([
    ...initialRequiredFields,
  ]);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    setIncompleteFields(incompleteFields.filter((field) => field !== event.target.name));
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };
    const submitHandler = async(e) => {
      console.log('HOLA',formData)
      const incompleteFieldsArray = checkIncompleteFields(requiredFields,formData);
      setIncompleteFields(incompleteFieldsArray);
      e.preventDefault();
      if (incompleteFieldsArray.length === 0) {
        try {
await sendQuotation(formData);
    createNotification(
          "success",
          "Nos pondremos en contacto con usted.",
          "Su formulario ha sido enviado con éxito"
        ) 
    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
    }
    setFormData(dataOptions);
  }    

    }

// useEffect(() => {
//   setFormData({
//     firstName: formData.firstName,
//     lastName: formData.lastName,
//     email: formData.email,
//     phone: formData.phone,
//     message: formData.message,
//   });
//   setRequiredFields([...initialRequiredFields]);
// }, []);


  return (
    <div className="principal-landing">
      <div className="container-text-landing">
        <h2>
          ¡REGÍSTRATE Y SOLICITA TUS
          <p>
            <font color="BLUE"> SEGUROS Y GARANTÍAS </font>
          </p>{" "}
          <p>DE LA FORMA MÁS RÁPIDA!</p>
        </h2>
      </div>
      <div className="container-img-landing">
        <img src={landing2}></img>
      </div>
      <div className="container-text-landing-end">
        <p>
          Con <font color="BLUE">Wanak</font> encontrarás lo que necesitas para
potenciar tu negocio, contrata tus garantías 100% online.
        </p>
      </div>
      <section className="landing-container">
        <form className="form-landing">
          <div className="form-security__form">
            <div className="form-landing__inputs">
              <div className="info-contact">
                <h1 className="welcome-text">
                  ¡Recibe más información sobre nuestras garantías y seguros!
                </h1>
              </div>
              <p>
                Al enviar tu información, uno de nuestros ejecutivos te contactará de inmediato.
              </p>

              <InputForm
                type="text"
                value={formData.firstName}
                name="firstName"
                title="Nombre"
                placeholder="Maria"
                handlerChange={inputHandler}
                error={incompleteFields.includes("firstName")}
              />
              <InputForm
                type="text"
                value={formData.lastName}
                name="lastName"
                title="Apellido"
                placeholder="Pizarro"
                handlerChange={inputHandler}
                error={incompleteFields.includes("lastName")}
              />
               <InputForm
                type="number"
                value={formData.phone}
                name="phone"
                title="Teléfono Móvil"
                placeholder="+56999999999"
                handlerChange={inputHandler}
                error={incompleteFields.includes("phone")}
              />
              <InputRut
                formData={formData}
                setFormData={setFormData}
                name="rut"
                title="RUT"
              />
              <InputForm
                type="email"
                name="email"
                title="Email"
                value={formData.email}
                placeholder="nombre@wanak.com"
                handlerChange={inputHandler}
                error={incompleteFields.includes("email")}
              />
            </div>
            <div className="container-button">
              <button
                type="submit"
                className="button-send-register"
                onClick={submitHandler}
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}