import "./styles.css";
import React, { useEffect, useState } from "react";
import arrowOpen from "../../assets/images/arrowOpen.svg";
import { Links } from "./dataSideBar";
import Item from "./Item";
import sbout from "../../assets/images/sbout.svg";
import sboutopen from "../../assets/images/sboutopen.svg";
import sboutclose from "../../assets/images/sboutclose.svg";
import { NavLink, useHistory } from "react-router-dom";
import imgPerfil from "./assets/perfil.svg";
import imgEmpresa from "./assets/empresa.svg";
import docImg from "./assets/documentos.svg";
import ejecutivoImg from "./assets/ejecutivo.svg";

function Sidebar({ ...props }) {
  const history = useHistory();
  const logout = () => {
    console.log("cerrando sesion");
    props.doLogout();
    props.resetCompanies();
    props.resetCart();
    history.push("/");
  };
  const { dataUser } = props.user;
  const { companies } = props.companies;
  const [open, setOpen] = useState(false);
  const [activeCompany, setActiveCompany] = useState(props.activeCompany);
  // const [name, setName] = useState(dataUser.ClientName)
  // const [nameDefault, setNameDefault] = useState(dataUser.ClientName)

  const openSideBar = () => {
    setOpen(true);
  };
  const closeSideBar = () => {
    setOpen(false);
  };

  useEffect(() => {
    setActiveCompany(props.activeCompany);
    console.log(activeCompany);
  }, [props.activeCompany]);

  return (
    <div className="container-principal-sliderbar">
      <div
        className={
          open
            ? "container-principal-slidebar-open"
            : "container-principal-slidebar-close"
        }
      >
        <div
          className={
            open
              ? "container-info-slidebar-open"
              : "container-info-slidebar-close"
          }
        >
          {open ? (
            <div className="container-contact-sliderbar float">
              <button className="btn-slidebar-close" onClick={closeSideBar}>
                <img src={sboutclose}></img>
              </button>
              <div className="container-name-company">
                <p className="name-client-slidebar">{dataUser.ClientName}</p>
                
                <p className="company-client-slidebar">
                  {companies.length == 0
                    ? "Sin empresa"
                    :`Empresa: ${activeCompany.CompanyName}`}
                </p>
              </div>
            </div>
          ) : (
            <button className="btn-slidebar-open" onClick={openSideBar}>
              <img src={arrowOpen}></img>
            </button>
          )}
          <div
            className={
              open ? "container-items-slidebar" : "container-items-close"
            }
          >
            <NavLink to="/profile" className="w-100 mb-3 mt-1 slide_bar_hover py-2 py-2">
              {/* <img src={imgPerfil}></img>
              Perfil */}
              {open ? <img src={imgPerfil}></img> : <img src={imgPerfil}></img>}
              {open ? (
                <div className="container-open-slidebar">
                  <p className="text-slidebar">Perfil</p>
                </div>
              ) : null}
            </NavLink>

            {companies.length == 0 ? (
              <NavLink to="/storeCompany" className="w-100 mb-3 slide_bar_hover py-2">
                {open ? (
                  <img src={imgEmpresa}></img>
                ) : (
                  <img src={imgEmpresa}></img>
                )}
                {open ? (
                  <div className="container-open-slidebar">
                    <p className="text-slidebar">Agregar Empresa</p>
                  </div>
                ) : null}
              </NavLink>
            ) : (
              <NavLink to="/editCompany" className="w-100 mb-3 slide_bar_hover py-2">
                {open ? (
                  <img src={imgEmpresa}></img>
                ) : (
                  <img src={imgEmpresa}></img>
                )}
                {open ? (
                  <div className="container-open-slidebar">
                    <p className="text-slidebar">Empresa</p>
                  </div>
                ) : null}
              </NavLink>
            )}

            <NavLink to="/documents" className="w-100 mb-3 slide_bar_hover py-2">
              {open ? <img src={docImg}></img> : <img src={docImg}></img>}
              {open ? (
                <div className="container-open-slidebar">
                  <p className="text-slidebar">Documentos</p>
                </div>
              ) : null}
            </NavLink>
            <NavLink to="/myExecutive" className="w-100 mb-3 slide_bar_hover py-2">
              {open ? (
                <img src={ejecutivoImg}></img>
              ) : (
                <img src={ejecutivoImg}></img>
              )}
              {open ? (
                <div className="container-open-slidebar">
                  <p className="text-slidebar">Mi Ejecutivo</p>
                </div>
              ) : null}
            </NavLink>

            {/* {Links.map(({ text, to, svg }) => (
              <Item open={open} to={to} svg={svg} text={text}>
                {text}
              </Item>
            ))} */}
          </div>
          {open ? (
            <button className="btn-back" onClick={logout}>
              <img src={sboutopen}></img>
            </button>
          ) : (
            <button className="btn-back" onClick={logout}>
              <img src={sbout}></img>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
