import React from 'react';
import InputForm from '../InputForm';
import './styles.css';

function DateInputs({
  setFormData, formData,
}) {
  const changeHandler = (event) => {
    const { target: { value, name } } = event;
    setFormData({
      ...formData, [name]: value,
    });
  };
  return (
    <div className="date_inputs__container">
      <InputForm
        title="Fecha Inicio"
        type="date"
        value={formData.startDate || ''}
        name="startDate"
        handlerChange={changeHandler}
      />
      <InputForm
        title="Fecha Término"
        type="date"
        value={formData.endDate || ''}
        name="endDate"
        handlerChange={changeHandler}
      />
    </div>
  );
}

export default DateInputs;
