import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputForm from "../../components/InputForm";
import InputSelect from "../../components/InputSelect";
import {
  checkIncompleteFields,
  createNotification,
} from "../../utils/helpers/helpers";
import "./style.css";
import { sendQuotation } from "../../utils/apis/quotation";
import {
  dataOptions,
  initialOption,
  options,
  initialRequiredFields,
} from "./data";
import otros1 from "../../assets/images/otros1.svg";
import otros2 from "../../assets/images/otros2.svg";
import otros3 from "../../assets/images/otros3.svg";
import otros4 from "../../assets/images/otros4.svg";


export default function Patrimony() {
  const history = useHistory();

  const [formData, setFormData] = useState({ ...dataOptions });
  const [selectedOption, setSelectedOption] = useState(options);
  const [requiredFields, setRequiredFields] = useState([
    ...initialRequiredFields,
  ]);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    setIncompleteFields(
      incompleteFields.filter((field) => field !== event.target.name)
    );

    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    console.log("select", selectedOption);
    console.log("entramos al submit");

    if (selectedOption !== null && selectedOption !== initialOption) {
      formData.message = selectedOption;
    }

    const incompleteFieldsArray = checkIncompleteFields(
      requiredFields,
      formData
    );
    console.log("heyy", formData);
    setIncompleteFields(incompleteFieldsArray);
    event.preventDefault();

    console.log("aqui vamos");
    if (incompleteFieldsArray.length === 0) {
      console.log("aaca llegamos");
      try {
        await sendQuotation(formData);
        history.push("/infoReceived")
        // createNotification(
        //   "success",
        //   "Nos pondremos en contacto con usted.",
        //   "Su formulario ha sido enviado con éxito"
        // );

      } catch (error) {
        createNotification(
          "error",
          "Por favor intentelo más tarde.",
          "Ha ocurrido un error al enviar su formulario"
        );
      }
      setFormData(dataOptions);
      setSelectedOption(initialOption);
    }
  };

  // Reset de los estados para cada opción
  useEffect(() => {
    setIncompleteFields([]);
    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    });
    setRequiredFields([...initialRequiredFields]);
  }, [selectedOption]);

  return (
    <div className="principal-security">
      <section className="security-container">
      <div className="container-cards-otros">
        <h5>Otros</h5>
        <p>En esta categoría agrupamos los siguientes seguros:</p>
      <div className="cards-info-otros">
        <img src={otros1} alt={"Seguro de asiento pasajero"}></img>
        <img src={otros2} alt={"Vehiculos"}></img>
        <img src={otros3} alt={"Vehiculos"}></img>
        <img src={otros4} alt={"Vehiculos"}></img>
      </div>
      </div>
        <form className="form-security" onSubmit={submitHandler}>
          <div className="form-security__form">
            <div className="form-security__inputs">
            <div className="info-contact">    
        <h5>Información de Contacto</h5>
        <p>Necesitamos estos datos para darte la cotización que necesitas, 
¿me los puedes
 Compartir?</p>
      </div>
              <InputForm
                type="text"
                value={formData.firstName}
                name="firstName"
                title="Nombre"
                placeholder="Maria"
                handlerChange={inputHandler}
                error={incompleteFields.includes("firstName")}
              />
              <InputForm
                type="text"
                value={formData.lastName}
                name="lastName"
                title="Apellido"
                placeholder="Pizarro"
                handlerChange={inputHandler}
                error={incompleteFields.includes("lastName")}
              />

              <InputForm
                type="email"
                name="email"
                title="Email"
                value={formData.email}
                placeholder="nombre@wanak.com"
                handlerChange={inputHandler}
                error={incompleteFields.includes("email")}
              />
              <InputForm
                type="number"
                value={formData.phone}
                name="phone"
                title="Teléfono Móvil"
                placeholder="+5699999999"
                handlerChange={inputHandler}
                error={incompleteFields.includes("phone")}
              />
            </div>
            <div className="form-contact__optional_inputs">
              <span className="input__title form_contact_second_title">
                Seguro de equipos
              </span>
              <div className="form_contact_select"></div>
              <div className="form_contact_select">
                <InputSelect
                  name="optionValue"
                  value={initialOption}
                  options={options}
                  handlerChange={({ target: { value } }) =>
                    setSelectedOption(value)
                  }
                  defaultValue="Selecciona un seguro..."
                />
              </div>
              <div className="form-contact-button">
                <button
                  type="submit"
                  className="button-quote-form"
                  onClick={submitHandler}
                >
                  Solicitar
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="container-products-for">
      {/* <p>Tenemos los siguientes seguros para ti:</p>
  */}
      </div>
      </section>
    </div>
  );
}
