import React, { useEffect } from 'react';
import './styles.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { validate } from '../../utils/apis/clientUsersApi';
import { doLogin } from '../../store/actions/userActions';
import { updateShoppingCart } from '../../utils/helpers/helpers';
import { addToCart } from '../../store/actions/shoppingCartActions';

function ValidatingScreen() {
  const { q } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const loginValidatedUser = async (userData) => {
    dispatch(doLogin(userData));
    updateShoppingCart(userData.ClientId, dispatch(addToCart()));
    history.push('/register/welcome');
  };

  useEffect(async () => {
    const validationResponse = await validate(q);
    console.log(validationResponse);
    loginValidatedUser(validationResponse);
  }, []);

  return (
    <div className="screen__container validating_screen_container" style={{ height: '90vh' }}>
      <div className="validating_container">
        <h1>Validando Registro...</h1>
        <Spinner />
      </div>
    </div>
  );
}

export default ValidatingScreen;
