/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import './styles.css';
import docImage from '../../assets/images/doc.png';
import SquareButton from '../../components/SquareButton';
import PdfModal from '../../components/PdfModal';
import getAceptaDocument from '../../utils/apis/aceptaApi';

function CheckWarranty() {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState('');
  const [showModal, setShowModal] = useState(false);

  const verifyDocument = async () => {
    try {
      setLoading(true);
      const res = await getAceptaDocument(inputValue);
      setLoading(false);
      setPdfData(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <section className="checkWarranty__container">
      <div className="checkWarranty__modal">
        <h1 className="title__checkWarranty">Verificar documento</h1>
        <div className="container__image">
        <figure className="checkWarranty__image">
          <img src={docImage} alt="of the documents" />
        </figure>
        </div>

        <div className="line" />
        <div className="arrow-down" />
        <div className="checkWarranty__input__container">
          <p className="checkWarranty__input__title">
            Número de documento
          </p>
          <input
            className="checkWarranty__input"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <div className='container__button__checkWarranty'>
        <SquareButton loading={loading} type="button" value="Verificar" clickHandler={() => verifyDocument()} />
        </div>
      </div>
      {showModal && <PdfModal pdfData={pdfData} setShowModal={setShowModal} />}
 
    </section>
  );
}

export default CheckWarranty;
