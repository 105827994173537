import React, { useState } from 'react';
import './styles.css';
import { faTrashAlt, faEye} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import { removeFromCart, getWarrantyDraftFromAPI } from '../../utils/apis/warrantiesApi';
import { createNotification, updateShoppingCart } from '../../utils/helpers/helpers';
import { addToCart } from '../../store/actions/shoppingCartActions';
import PdfModal from '../../components/PdfModal';

function CartItem({
  item, company, user, ...props
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pdfData, setPdfData] = useState('');
  const [loading, setLoading] = useState(false);


  const previewWarrantyDraft = async (warrantyId) => {
    try {
      setLoading(true);
      const res = await getWarrantyDraftFromAPI(warrantyId);
      setLoading(false);
      setPdfData(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const removeItemAndUpdateStore = async (warrantyId) => {
    setRemovingItem(true);
    try {
      await removeFromCart(warrantyId);
      await updateShoppingCart(user.dataUser.ClientId, props.addToCart);
      createNotification('success', 'Garantía eliminada con éxito');
    } catch (error) {
    } finally { setRemovingItem(false); }
  };

  const deleteIcon = () => (!removingItem ? (
    <FontAwesomeIcon
      icon={faTrashAlt}
      onClick={() => removeItemAndUpdateStore(item.WarrantyId)}
    />
  ) : (<Spinner />));

    const previewIcon = () => (
      <FontAwesomeIcon
        icon={faEye}
        onClick={() => previewWarrantyDraft(item.WarrantyId)}
        />
    );

  return (
    <div className="product_container">
      <div className="product_info_container">
        <h1>{item.WarrantyCode}</h1>
        <h3 className="item_name">{item.WarrantyName}</h3>
        <h3>
          <strong>Finalidad:</strong>
          {' '}
          {item.WarrantyType}
        </h3>
        <div className="shrink product_info_glosa">
          {/* <span style={{ fontSize: '1.2em', marginRight: '1em' }}>Glosa: </span> */}
          <p>{item.Glosa}</p>
        </div>
        <p className="product_info_date">
          {item.StartDate.split('T')[0]}
          {' '}
          |
          {' '}
          {item.Enddate.split('T')[0]}
        </p>
      </div>
      <div className="product_left_container">
        <div className="product_actions_container">
          {/* Editar aun no disponible */}
          {/* <div className="product_action" style={{ backgroundColor: 'var(--secondary-color)' }}>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => setShowEditModal(true)}
            />
          </div> */}
          <div className="product_action" style={{ backgroundColor: '#f90f01' }}>
            {deleteIcon()}
          </div>
          <div className="product_action" style={{ backgroundColor: 'var(--primary-color)' }}>
            {previewIcon()}
          </div>
          {/* ) : (
            <FontAwesomeIcon
              icon={faSpinner}
              color="red"
              size="1x"
              pulse
              style={{ marginLeft: 10, backgroundColor: 'transparent',
               color: 'var(--secondary-color)' }}
              onClick={() => openEditModal()}
            />
          )} */}
        </div>
        <div className="product_price_container">
          <h1>
            $
            {item.WarrantyCost}
          </h1>
        </div>
      </div>

      {/* Modal para editar la garantía antes de comprar, aún no existe la función en el back */}

      {/* {showEditModal && (
      <EditCartItemModal
        setShowModal={setShowEditModal}
        warranty={item}
        company={company}
      />
      )} */}
      {showModal && <PdfModal pdfData={pdfData} setShowModal={setShowModal} />}

    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});

export default connect(null, mapDispatchToProps)(CartItem);
