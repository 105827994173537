import React, { useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import ingeWanak from "../../../../assets/images/ingeWanak.gif";
import rocket from "../../../../assets/images/rocket-webp.webp";
import imgSlide2 from "../../../../assets/images/imgSlide2-webp.webp";
import newHeader from "../../../../assets/images/newHeader.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CarouselInitial() {
  const [initial, setInitial] = useState(0);

  const handleSelect = () => {
    setInitial(initial);
  };
  const history = useHistory();
  return (
    <Carousel activeInitial={initial} onSelect={handleSelect}>
      <Carousel.Item interval={3000}>
        <img className="img-carousel-1" src={rocket} alt="Second slide" />
        <Carousel.Caption>
          <div className="content-img-1">
            <h1 className="subtitle-img-1">
              <strong> ¡SOLICITA </strong> <p>Y OBTÉN LO QUE NECESITAS!</p>
            </h1>
            <div className="container-text-end-1">
              <p className="text-img-1-end">
                Entregamos tus documentos legales de forma efectiva e
                instantánea, compra por nuestra página o un ejecutivo puede
                contactarte.
              </p>
            </div>
            <button
              className="button__action"
              onClick={() => history.push("/Quoter")}
            >
              Cotiza
            </button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={30000}>
        <img className="img-carousel-2" src={imgSlide2} alt="Third slide" />
        <Carousel.Caption>
          <div className="content-img-2">
            <div className="subtitle__suscribe">
              <h1 className="subtitle-img-2">
                <strong> ¡SUSCRÍBETE </strong> <p>A NUESTRO NEWSLETTER!</p>
              </h1>
              <p className="text-img-2-end">
                Recibe las últimas novedades sobre licitaciones y artículos de
                nuestro blog
              </p>
            </div>
            <div className="suscribete__btn">
              <a href="https://blog.wanak.com/contactanos/">
                <button className="button__action__suscribe">Suscríbete</button>
              </a>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
