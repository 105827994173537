import iconCredit from './assets/wallet-webp.webp'
import iconG from './assets/contract-webp.webp'
import iconF from './assets/invoice-webp.webp'
// import iconCredit from '../../assets/images/iconCredit.svg'
// import iconG from '../../assets/images/iconG.svg'
// import iconF from '../../assets/images/iconF.svg'
import './style.css'
import { connect,useSelector  } from "react-redux";



function Service({ user: { isAuth } ,setPage}) {
  const items = [
    {
     subtitle: "Capital de Trabajo",
      img: iconCredit,
      text: 'Obtén el crédito sin garantía que necesitas',
     url: <button className="button__blue" onClick={() => setPage(1)}>Cotizar</button>
  
    },
    {
      subtitle: "Garantias",
      img: iconG,
      text: 'Adquiere los documentos que vas a necesitar para tus licitaciones o tratos directos.',
      url: <button className="button__blue" onClick={() => setPage(2)}>Cotizar</button>
    },
    {
      subtitle: "Factoring",
      img: iconF,
      text:'Mejora el flujo de caja de tu empresa con Factoring InGe!.',
      url: <button className="button__blue" onClick={() => setPage(3)}>Cotizar</button>
    },
  ];

return (
    <div className="container__principal"> 
<div className="subtitle__quoter">
  <h1><strong>¿Que servicio te gustaría cotizar?</strong></h1>
</div>
  <div className="box__cards__quoter">
    {items.map((item) => (
      <div className="cards">
        <h2>{item.subtitle}</h2>
        <img className="img__icon" src={item.img} alt={"imagen"}/>
        <div className="container__text__quoter">
        <p>{item.text}</p>
        </div>
        <div className="btn__quoter">
        {item.url}
        </div>
        
      </div>
    ))}

  </div>
</div>

)
}
const mapStateToProps = ({ user, companies }) => ({ user, companies});
export default connect(mapStateToProps)(Service);