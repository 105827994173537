/* eslint-disable max-len */
import React from 'react';

function TermsConditions() {
  return (
    <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
      Este contrato describe los términos y condiciones generales aplicables al uso de los contenidos, productos y servicios
      ofrecidos a través del sitio web de www.wanak.com de cual es titular
      {' '}
      <strong>INGE</strong>
      {' '}
      S.A.G.R. es una sociedad anónima cuyo
      objeto principal es el otorgamiento de garantías personales a los acreedores de sus beneficiarios, con la finalidad de
      caucionar obligaciones que ellos contraigan, relacionadas con sus actividades empresariales, productivas,
      profesionales o comerciales, en los términos establecidos en la Ley N°20.179 que establece el marco legal para la
      constitución y operación de sociedades de garantía recíproca.
      <br />
      <br />
      Aceptando el Contrato de Garantía Recíproca, en los términos establecidos por la Ley N°20.179, en virtud del cual
      {' '}
      <strong>INGE</strong>
      {' '}
      se obliga para con la Beneficiaria a afianzar las
      obligaciones que ésta contraiga con sus acreedores. Por su parte, se obliga principalmente a cumplir y pagar a sus
      acreedores las obligaciones garantizadas en forma fiel, íntegra y oportuna, sean éstas obligaciones de dar, hacer o no
      hacer, y a resarcir a
      {' '}
      <strong>INGE</strong>
      {' '}
      de cualquier pago o desembolso que ésta se viera obligada a efectuar a favor de sus
      acreedores, como consecuencia de la fianza otorgada, y de todo los gastos y desembolsos en que ésta incurra, ya
      sea por concepto de capital, reajustes, intereses, comisiones, gastos de cobranza extrajudicial y judicial, costas
      personales y procesales, honorarios de abogados y, en general, de cualquier otro gasto o desembolso que
      {' '}
      <strong>INGE</strong>
      hiciere con ocasión de la fianza otorgada. Se deja expresa constancia que el Contrato de Garantía Recíproca incluye
      sus anexos y respectivos certificados de fianza emitidos con ocasión del mismo.
      {' '}
      <br />
      <br />
      Las Obligaciones Principales de la
      Beneficiaria. En virtud de este contrato, la Beneficiaria se obliga principalmente a: Dar fiel, íntegro y oportuno
      cumplimiento a las Obligaciones Afianzadas, en los términos y condiciones pactados con sus acreedores,
      Reembolsar y resarcir a
      {' '}
      <strong>INGE</strong>
      {' '}
      por los pagos y desembolsos que ésta se viera precisada a efectuar a favor de los
      acreedores de la Beneficiaria, como consecuencia de la fianza otorgada, y de todo los gastos y desembolsos en que
      ésta incurra, ya sea por concepto de capital, reajustes, intereses, comisiones, gastos de cobranza extrajudicial y
      judicial, costas personales y procesales, honorarios de abogados y, en general, de cualquier otro gasto o desembolso
      que
      {' '}
      <strong>INGE</strong>
      {' '}
      hiciere con ocasión de la fianza otorgada. Estos reembolsos deberán efectuarse debidamente reajustados
      aplicando la variación que hubiere experimentado la Unidad de Fomento entre la fecha en que
      {' '}
      <strong>INGE</strong>
      {' '}
      materializó el
      pago o desembolso y la fecha en que se proceda a su reembolso, más el interés máximo que la ley permita estipular
      para operaciones reajustables (o no reajustables, según sea el caso) aplicado sobre el total de las sumas pagadas o
      desembolsadas por
      {' '}
      <strong>INGE</strong>
      . Si por cualquier causa o motivo, la Unidad de Fomento fuere reemplazada, sustituida o
      suprimida, el reajuste se determinará conforme al parámetro que la reemplace, sustituya o haga sus veces,
      Indemnizar a
      {' '}
      <strong>INGE</strong>
      {' '}
      de todo perjuicio que sufra o llegare a sufrir con motivo o causa del incumplimiento por parte de le
      Beneficiaria de las Obligaciones Afianzadas. Las cantidades, que por cualquier concepto resultaren en contra de la
      Beneficiaria y a favor de
      {' '}
      <strong>INGE</strong>
      , se adeudarán por aquel y serán exigibles de acuerdo a este contrato como si fueren
      de plazo vencido, aun estando vigente, total o parcialmente, la obligación garantizada por
      {' '}
      <strong>INGE</strong>
      {' '}
      y sin necesidad de
      que ésta última haya pagado en todo o parte a los acreedores designados en el Certificado de Fianza. La Beneficiaria
      autoriza expresa e irrevocablemente a
      {' '}
      <strong>INGE</strong>
      {' '}
      para que proceda a pagar a sus acreedores las cantidades que le sean
      requeridas por éste, como consecuencia mediata o inmediata de la ejecución del Certificado de Fianza emitido, al
      primer requerimiento judicial o extrajudicial que reciba para ello, sin necesidad de contar con su previa conformidad u
      otra autorización. Con el objeto de facilitar el cobro la Beneficiaria otorga mandato especial e irrevocable a
      {' '}
      <strong>INGE</strong>
      {' '}
      para
      que, en su representación, y actuando a través de sus representantes, suscriba y complete uno o más pagarés a la
      vista emitidos a la orden de
      {' '}
      <strong>INGE</strong>
      {' '}
      y/o reconozca las deudas referidas a favor de la misma.
      {' '}
      <br />
      <br />
      {' '}
      Con el objeto de dar
      cumplimiento a la Ley 19.628 sobre Protección de Datos de Carácter Personal, la Beneficiaria faculta
      irrevocablemente a
      {' '}
      <strong>INGE</strong>
      {' '}
      para que pueda dar a conocer la morosidad o atraso o infracción de cualesquiera de las
      Obligaciones Afianzadas y/o de las obligaciones contraídas con
      {' '}
      <strong>INGE</strong>
      , sus accesorios, números de la o las
      operaciones, detalle de deudas, nombres y rol único tributario del Beneficiario, teléfonos, celulares, correos
      electrónicos, domicilios, residencias o moradas, etcétera, como de cualesquiera de las obligaciones contraídas por la
      Beneficiaria mediante este instrumento, proporcionando dicha información a cualquier registro o banco de datos
      personales de carácter público, con el fin u objeto de que las morosidades o incumplimientos señalados sean
      procesados, tratados, comunicados, publicados y/o divulgados por dichos medios a terceros, relevando la Beneficiaria
      a
      {' '}
      <strong>INGE</strong>
      {' '}
      de cualquier responsabilidad que se pudiere derivar al efecto. Por medio de la aceptación de los presentes
      términos y condiciones del contrato, el usuario de forma voluntaria, expresa su acepta y reconoce como válidas y
      vinculantes, para todos los efectos legales, las normas del Código Civil y el de Comercio, así como también por la Ley
      Sobre Protección de la Vida Privada y la Ley de Derechos del Consumidor esta manifestación de aceptación.
    </p>
  );
}

export default TermsConditions;
