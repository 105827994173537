import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import PdfModal from '../../components/PdfModal';
import Spinner from '../../components/Spinner';
import getAceptaDocument from '../../utils/apis/aceptaApi';
import { getMyWarrantiesFromAPI } from '../../utils/apis/warrantiesApi';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import MobileTable from '../../components/MobileTable/MobileTable';

const columnsWarranties = [
  {
    title: 'Fecha',
    field: 'DateString',
    disableClick: true,
  },
  {
    title: 'Código',
    field: 'DocumentCode',
    disableClick: true,
  },
  {
    title: 'Caduca',
    field: 'EndDateString',
    disableClick: true,
  },
  {
    title: 'Monto',
    field: 'AmountString',
    disableClick: true,
  },
  {
    title: 'Licitación',
    field: 'TenderCode',
    headerStyle: { },
    disableClick: true,
  },
  {
    title: 'Estado',
    field: 'State',
    disableClick: true,
  },
];

const tableOptions = {
  search: false,
  showTitle: false,
  toolbar: false,
  paging: false,

};

function WarrantiesTable({ company }) {

  const [tableData, setTableData] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [pdfData, setPdfData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emptyTableMessage, setEmptyTableMessage] = useState(null);
  const [width] = useWindowDimentions();

  const getWarranty = async ({ DocumentCode }) => {
    try {
      setLoadingDocument(true);
      const res = await getAceptaDocument(DocumentCode);
      setLoadingDocument(false);
      setPdfData(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setLoadingDocument(false);
    }
  };

  const documentIcon = () => (
    !loadingDocument ? (
      <FontAwesomeIcon
        icon={faDownload}
        color="var(--secondary-color)"
        style={{ fontSize: 14 }}
      />
    )
      : <Spinner />
  );
  useEffect(async () => {
    setTableData([]);
    setEmptyTableMessage(null);
    try {
      const res = await getMyWarrantiesFromAPI(company.CompanyId);
      res.sort(function (a, b) {
        if (a.ItemId < b.ItemId) {
          return 1;
        }return -1;
      }
      );
      if (res.length === 0) {
        setEmptyTableMessage('Su empresa no posee garantias compradas.');
      }
      console.log('array',res);
      
      setTableData(res);
      console.log('array',res);

    } catch (error) {
      console.log(error);
    }
  }, [company]);


  return (
    <div>
      {width > 850 ? (
        <DataTable
          emptyMessage={emptyTableMessage}
          data={tableData}
          columns={columnsWarranties}
          options={tableOptions}
          stylesContainer={{ width: 'auto', margin: 'auto' }}
          actions={[{
            icon: () => documentIcon(),
            tooltip: 'Verificar Garantía',
            onClick: (event, row) => getWarranty(row),
          }]}
        />
      )
        : (
          <MobileTable
            data={tableData}
            emptyMessage={emptyTableMessage}
            columns={columnsWarranties}
            options={tableOptions}
            stylesContainer={{ width: 'auto', margin: 'auto' }}
            actions={[{
              icon: () => documentIcon(loadingDocument),
              onClick: getWarranty,
              round: true,
            },
            ]}
            headers={['DocumentCode']}
          />
        )}
      {showModal && <PdfModal pdfData={pdfData} setShowModal={setShowModal} />}
    </div>
  );
}

export default WarrantiesTable;
