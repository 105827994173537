import "./style.css";
import React, { useState } from "react";
import logoSii from "../../assets/images/logoSii.png";
import InputRut from "../../components/InputForm/InputRut";
import InputPassword from "../../components/InputForm/InputPassword";
import btnClose from "../../assets/images/btnClose.svg"

function ModalSii({formData, setFormData, setModalSii}) {



  const handlerInput = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const closeModal = () => {
    setModalSii(false);
  };


  return (
    <div className="container__modal__simulation">
      <section className="section__modal__sii">
        <span>Recuerda que puedes operar sin papeles con tu clave Sii <p><strong>registra tu clave aquí</strong></p></span>
      
      <div className="modal__clave">
        <div className="header__modal__clave">
        <img src={logoSii} alt="logoSii" className="logoSii"></img>
          <p>
         Clave Tributaria
          </p>
          {/* <button type="button" className="btn__close__clave" onClick={closeModal}><img className="btn-close-modalSii" src={btnClose} alt="close"></img></button> */}
        </div>
       
<div className="container__inputs__claveSii">
    <div className="">
    <InputRut
          formData={formData}
          setFormData={setFormData}
          name="rut"
          title="RUT"
          value={formData.rut}
          handlerchange={handlerInput}
          className="input__save__rut"
        />
    </div>



        <InputPassword
            name="passwordSii"
            title="Ingresar Clave Tributaria"
            placeholder="*********"
            value={formData.passwordSii}
            handlerChange={handlerInput}
            className="input__save__clave"
          />
</div>

        <div className="contenedor__modal__clave">
          <button type="submit"  className="button__save__clave" onClick={closeModal}>GUARDAR</button>
          <div className="container__button__modalError"></div>
        </div>
      </div>
      <a onClick={closeModal}>
      <h3>Omitir este paso</h3>
      </a>
  
      </section>
      </div>

  );
}

export default ModalSii;
