// import axios from 'axios';
import requestBaseMethod from './baseApi';

export const createWebPayTransactionApi = (data) => {
  const url = 'Transbank/NewTransaction';
  return requestBaseMethod(url, 'POST', data);
};
export const createFreeTransaction = (data) => {
  const url = 'Transbank/FreeTransaction';
  return requestBaseMethod(url, 'POST', data);
};
// export const goToTransaction = (url, token) => {
//   const data = { token_ws: token };
//   return requestBaseMethod('', 'POST', data, url);
// };

// export const validateTransaction = async (token) => {
//   const url = `https://webpay3gint.transbank.cl/rswebpaytransaction/api/webpay/v1.0/transactions/${token}`;
//   const headers = {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Tbk-Api-Key-Id': '597055555532',
//     'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
// eslint-disable-next-line max-len
//     'Access-Control-Allow-Headers': 'append,delete,entries,foreach,get,has,keys,set,values,Authorization',
//     'Tbk-Api-Key-Secret': '579B532A7440BB0C9079DED94D31EA1615BACEB56610332264630D42D0A36B1C',
//   };
//   const method = 'GET';
//   return new Promise((resolve, reject) => {
//     axios({
//       method,
//       url,
//       headers,
//     })
//       .then((response) => resolve(response.data))
//       .catch((error) => reject(error));
//   });
// };
