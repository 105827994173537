import React from 'react';
import './styles.css';

import ModalTemplate from '../ModalTemplate';

function TenderDetailsModal({
  // show,
  tender,
  simulateTender,
  closeModal,

}) {
  const handlerClick = () => {
    closeModal();
    simulateTender();
  };

  // if (show) {
  return (
  // <div className="modal-tenders">
    <ModalTemplate closeModal={() => closeModal()}>
      <div className="modal-tenders__container">
        <div className="modal_tenders_info_container">
          <h1 className="modal-tenders__title">
            {tender.Name}
          </h1>
          <h2 className="modal-tenders__code">
            {tender.Code}
          </h2>
          <p className="modal-tenders__description">
            {tender.Description}
          </p>
          <div className="modal-tenders__buyer">
            <p>{tender.TenderBuyer.UnitName}</p>
            <span>{tender.TenderBuyer.UnitLocation}</span>
          </div>
        </div>
        <div className="modal-tenders__button_container">
          <button
            type="button"
            className="modal-tenders__button"
            onClick={handlerClick}
          >
            Simular Garantía
          </button>
        </div>
      </div>

    </ModalTemplate>
  );
}

export default TenderDetailsModal;
