import { faChevronRight, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function MobileDropdownLink({
  options, link, icon, logout,
}) {
  const [showCollapsable, setShowCollapsable] = useState(false);

  // useEffect(() => {
  //   setShowCollapsable(false);
  // }, [showMenu]);

  return (
    <li className="mobile__nav__link">
      {link.path ? (
        <Link className="link" to={link.path}>
          {icon}
          <div className="link_text">{link.label}</div>
        </Link>
      )
        : (
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => setShowCollapsable((show) => !show)}
          >
            <div className="link_text">{link.label}</div>
            <FontAwesomeIcon className={`${showCollapsable ? 'link_collapsable_icon_open' : 'link_collapsable_icon_closed'}`} icon={faChevronRight} style={{ color: 'var(--primary-color)', fontSize: '1rem' }} />
          </div>
      // </div>
        )}
      <ul className={`mobile_dropdown ${showCollapsable ? 'mobile_dropdown--open' : 'mobile_dropdown--closed'}`}>
        {options && options.map(({ label, path, icon: linkIcon }) => (
          label === 'Salir'
            ? (
              <div
                className="mobile_dropdown_link"
                role="button"
                tabIndex={0}
                onClick={() => logout()}
              >
                <div className="link exit_link">
                  {label}
                  {linkIcon || ''}
                </div>
              </div>
            ) : (
              <li
                className="mobile_dropdown_link"
              >
                <Link className="link" to={path} >
                  {linkIcon || ''}
                  {label} 
                </Link>
              </li>
            )
        ))}
      </ul>
    </li>
  );
}

export default MobileDropdownLink;
