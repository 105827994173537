import { LOGIN, LOGOUT, SET_USER } from '../constants/userConstants';

export const doLogin = (payload) => ({
  type: LOGIN,
  payload,
});

export const doLogout = () => ({
  type: LOGOUT,
});

export const setUserEdit =(payload) => ({
  type: SET_USER,
  payload,
});