import React, { useState } from "react";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import "./style.css";
import { dataOptions } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import { sendFormFactoring } from "../../utils/apis/quotation";
import { useHistory } from "react-router-dom";
import { createNotification } from "../../utils/helpers/helpers";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function Factoring() {
  const history = useHistory();
  const [formData, setFormData] = useState({ ...dataOptions });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const handlerOnChange = (value) => {
    console.log("captcha value", value);
  };

  //VALIDANDO BOTÓN DE ENVIAR
  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["firstName", "rutProveedor", "email", "phone"],
      formData
    );
    console.log("campos vacios", incompleteFieldsArray);
    if (incompleteFieldsArray.length == 0) setSubmitButtonEnabled(false);
    else setSubmitButtonEnabled(true);
  };

  //  //HOOK DE EFECTO PARA VALIDAR BUTTON Y QUE SE INICIALICE EN LA DATA
  useEffect(() => {
    validateButton();
  }, [formData]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendFormFactoring(formData);
      console.log("esto se envió", formData);
      history.push("/infoReceived");
    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
    }
  };

  return (
    <div className="principal__factoring">
      <div className="container__text__factoring">
        <h3>¡Te ayudamos a mejorar la liquidez de tu empresa con Factoring!</h3>
        <p>
          Esta forma de financiamiento se realiza a través de un contrato en
          donde una empresa cede las facturas que tiene por cobrar y recibe a
          cambio el pago inmediato de las mismas.
        </p>
        <h2>Beneficios del factoring</h2>
        <p>
          <ol>
            <li>Liquidez Inmediata</li>
            <li>La deuda no se registra en el sector financiero</li>
            <li>Mejora el flujo de caja</li>
            <li>Ahorro en costos</li>
          </ol>
        </p>
        <h2>¿Quieres hacer un factoring con nosotros?</h2>
        <p>
          Si deseas tomar esta alternativa con nosotros para tener tranquilidad
          y mayor liquidez en tu empresa, deja tus datos en el formulario de
          contacto y te llamaremos.
        </p>
      </div>
      <section className="factoring-container">
        <div className="container-form__factoring">
          <form className="form-factoring">
            <GoogleReCaptchaProvider reCaptchaKey="6LdW_9khAAAAAEGx6saxoqhGp0rqrJAGrGRJVUuo">
              <div className="form-security__form">
                <div className="form-security__inputs">
                  <div className="info-contact">
                    <h5>Información de Contacto</h5>
                    <p>
                      Necesitamos estos datos para darte la cotización que
                      necesitas, ¿me los puedes Compartir?
                    </p>
                  </div>
                  <InputForm
                    type="text"
                    value={formData.firstName}
                    name="firstName"
                    title="Nombre Completo*"
                    placeholder="Maria"
                    handlerChange={inputHandler}
                    error={incompleteFields.includes("firstName")}
                  />
                  <InputRut
                    formData={formData}
                    setFormData={setFormData}
                    name="rutProveedor"
                    title="Rut Empresa*"
                    value={formData.rutProveedor}
                    className="input__rut__factoring"
                  />

                  <InputForm
                    type="email"
                    name="email"
                    title="Email*"
                    value={formData.email}
                    placeholder="nombre@wanak.com"
                    handlerChange={inputHandler}
                    error={incompleteFields.includes("email")}
                  />
                  <InputForm
                    type="number"
                    value={formData.phone}
                    name="phone"
                    title="Teléfono Móvil*"
                    placeholder="+5699999999"
                    handlerChange={inputHandler}
                    error={incompleteFields.includes("phone")}
                  />
                  <InputForm
                    type="number"
                    value={formData.ticket}
                    name="ticket"
                    title="Monto Factura (opcional)"
                    placeholder="000 000 000"
                    handlerChange={inputHandler}
                  />
                  <div className="container__rut__factoring">
                    {/* <InputRut
                  formData={formData}
                  setFormData={setFormData}
                  name="rutProveedor"
                  title="Rut Proveedor (opcional)"
                  value={formData.rutProveedor}
                  className="input__rut__factoring"
                /> */}
                    <InputRut
                      formData={formData}
                      setFormData={setFormData}
                      name="rutReceptor"
                      title="Rut Pagador (opcional)"
                      value={formData.rutReceptor}
                      className="input__rut__factoring"
                    />
                  </div>
                </div>
                <div className="form-contact__optional_inputs">
                  <div className="form-contact-button">
                    <button
                      type="submit"
                      className="button__gray"
                      onClick={submitHandler}
                      disabled={submitButtonEnabled}
                    >
                      Cotizar
                    </button>
                  </div>
                </div>
              </div>
            </GoogleReCaptchaProvider>
          </form>
        </div>
      </section>
    </div>
  );
}
