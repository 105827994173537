import React, { useEffect, useState } from 'react';
import './styles.css';
import { checkRut, prettifyRut } from 'react-rut-formatter';
import InputForm from '.';

function InputRut({
  formData, setFormData, name, className, title, error, styles
}) {
  const [rutIsValid, setRutIsValid] = useState(true);

  const rutInputHandler = (event) => {
    const { target: { value, name: key } } = event;
    const prettyRut = prettifyRut(value);
    setRutIsValid(checkRut(prettyRut));
    setFormData({
      ...formData,
      [key]: prettyRut,
    });
  };

  return (
    <div style={styles ? styles : { width: '100%', margin: 0, padding: 0 }} className={`rut_input_container ${className || ''}`}>
      <InputForm
        type="text"
        placeholder="9.999.999-9"
        name={name}
        title={title}
        value={formData[name]}
        handlerChange={(e) => rutInputHandler(e)}
        error={error}
      />
      {!rutIsValid && <p className="invalid_rut">Rut invalido</p>}
    </div>
  );
}

export default InputRut;
