export const dataOptions = {
    contactFormId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  
  export const initialOption = "Selecciona un seguro";
  
  export const options = [
    {
      value: "Seguro de accidentes personales",
      label: "Seguro de accidentes personales",
    },
    {
      value: "Seguro de protección financiera",
      label: "Seguro de protección financiera",
    },
    {
        value: "Seguro obligatorio covid-19",
        label: "Seguro obligatorio covid-19",
      },
  ];
  
  export const initialRequiredFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "message",
  ];
  