import { useState, useEffect } from 'react';

const useWindowDimentions = () => {
  const [dimentions, setDimentions] = useState([window.innerWidth, window.innerHeight]);

  const handleResize = () => {
    setDimentions([window.innerWidth, window.innerHeight]);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimentions;
};

export default useWindowDimentions;
