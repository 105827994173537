/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { addOwner } from '../../utils/apis/ownersApi';
import ModalTemplate from '../ModalTemplate';
import Loading from './steps/Loading';
import OwnerForm from './steps/OwnerForm';
import OwnerResponse from './steps/OwnerResponse';

function OwnersModal({
  setShowModal, user, defaultCompany, refreshOwners,
}) {
  const newOwnerInitialState = {
    name: '',
    rut: '',
    address: '',
    email: '',
  };

  const [newOwnerData, setNewOwnerData] = useState(newOwnerInitialState);
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const resetSteps = () => {
    setStep(1);
  };

  const closeModal = () => {
    setShowModal(false);
    resetSteps();
    setNewOwnerData(newOwnerInitialState);
  };

  useEffect(async () => {
    if (step === 2) {
      try {
        await addOwner({
          ...newOwnerData,
          companyOwnerId: user.ClientId,
          companyId: defaultCompany.CompanyId,
        });
        nextStep();
        refreshOwners();
      } catch (error) {
        console.log(error);
      }
    }
    if (step === 4) {
      closeModal();
    }
  }, [step]);
  return (
    <ModalTemplate closeModal={() => closeModal()} title={step === 2 ? '' : 'Agregar Socio'}>
      {step === 1
      && (
      <OwnerForm
        newOwnerData={newOwnerData}
        setNewOwnerData={setNewOwnerData}
        nextStep={nextStep}
      />
      )}
      {step === 2
      && (
      <Loading
        newOwnerData={newOwnerData}
        setNewOwnerData={setNewOwnerData}
        nextStep={nextStep}
      />
      )}
      {step === 3
      && (
      <OwnerResponse
        newOwnerData={newOwnerData}
        nextStep={nextStep}
      />
      )}

    </ModalTemplate>

  );
}

export default OwnersModal;
