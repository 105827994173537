import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import { chile } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import Button from "../../components/Button";
import lugage from "../../assets/images/lugage.svg";
import Form from "../../components/Form";
import ModalSii from "./ModalSii";
import { ProgressBar } from "react-bootstrap";

export default function DataCompany({ formData, setFormData, setPage }) {
  const [idChile, setIdChile] = useState(-1);
  const [modalSii, setModalSii] = useState(true);
  const [incompleteFields, setIncompleteFields] = useState([
    "reason",
    "rutCompany",
    "giro",
    "address",
    "number",
    "region",
    "comuna",
  ]);
  const history = useHistory();
  const checkFormData = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["reason", "rutCompany", "giro", "address", "number", "region", "comuna"],
      formData
    );
    setIncompleteFields(incompleteFieldsArray);
  };

  localStorage.setItem("data company", JSON.stringify(formData));

  const handlerRegion = (e) => {
    const option = e.target.value;
    console.log("option", option);
    setIdChile(option);
    setFormData({
      ...formData,
      region: chile.regiones[option].region,
    });
    checkFormData();
    console.log("data", formData);
  };
  const handlerComuna = (e) => {
    const option = e.target.value;
    setFormData({
      ...formData,
      comuna: chile.regiones[idChile].comunas[option],
    });

    checkFormData();
  };
  const handlerInput = (event) => {
    const name = event.target.name;
    const lastName = event.target.lastName;
    const { value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      [lastName]: value,
    });
    checkFormData();
  };

  console.log("region", chile.regiones);
  return (
    <section className="create__container">
      {modalSii ? (
        <ModalSii
          formData={formData}
          setFormData={setFormData}
          setModalSii={setModalSii}
        />
      ) : (
        false
      )}
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard">
          <ProgressBar now={30} />
        </div>
      </div>
      <div className="form__container__create">
        <div className="container__title__create">
          <h2 className="create__title">COMPLETA TU REGISTRO</h2>
          <p className="create__subtitle">
            Con nosotros obtendrás lo que necesites para tu licitación de forma
            rápida y online
          </p>
        </div>
        <div className="store-company__form__inputs-container">
          <div className="company_form_inputs">
            <InputForm
              type="text"
              name="reason"
              title="Nombre/Razón Social"
              value={formData.reason}
              handlerChange={handlerInput}
              className="flex_4"
            />
            <InputRut
              formData={formData}
              setFormData={setFormData}
              name="rutCompany"
              title="RUT"
              value={formData.rutCompany}
              onChange={checkFormData}
              className="flex_2"
            />
            <InputForm
              type="text"
              name="giro"
              title="Giro"
              value={formData.giro}
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_6"
            />
          </div>
          <div className="company_form_inputs">
            <InputForm
              type="text"
              name="address"
              title="Dirección"
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_3"
            />
            <div className="company_form_inputs company_form_inputs--small flex_3">
              <InputForm
                type="text"
                name="contact"
                title="Nombre de contacto"
                value={formData.contact}
                onChange={(event) => setFormData(event.target.value)}
                handlerChange={handlerInput}
              />
              <InputForm
                type="number"
                name="number"
                title="Número"
                onChange={(event) => setFormData(event.target.value)}
                handlerChange={handlerInput}
                className="flex_3"
              />
              <InputForm
                type="number"
                name="officeNumber"
                title="N° Oficina"
                onChange={(event) => setFormData(event.target.value)}
                handlerChange={handlerInput}
                className="flex_3"
              />
            </div>
          </div>
          <div className="company_form_inputs">
            <select
              className="select-regiones"
              name="region"
              onChange={(e) => {
                handlerRegion(e);
              }}
            >
              <option>Región</option>
              {chile.regiones.map((item, index) => (
                <option value={index}>{item.region}</option>
              ))}
            </select>
            <select
              className="select-comunas"
              name="comuna"
              onChange={(e) => {
                handlerComuna(e);
              }}
            >
              <option>Comuna</option>
              {idChile > -1 &&
                chile.regiones[idChile].comunas.map((item, index) => (
                  <option value={index}>{item}</option>
                ))}
            </select>
            <InputForm
              type="text"
              name="email"
              title="Email"
              placeholder="nombre@wanak.com"
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_3"
            />
          </div>
          <div className="container__button__create">
            <button
              disabled={incompleteFields.length == 0 ? false : true}
              className="button__next"
              onClick={() => setPage(2)}
            >
              CONTINUAR
            </button>
          </div>
        </div>
      </div>
      <div className="container__buttons__create">
        <div className="container-btn-later">
          <Form
            buttonValue="Agregar más tarde"
            type="register"
            formData={formData}
            className="btn"
          ></Form>
        </div>
        <button
          type="button"
          className="btn__back__register"
          onClick={() => {
            setPage(0);
          }}
        >
          Atrás
        </button>
      </div>
    </section>
  );
}
