import requestBaseMethod from './baseApi';

export const sendQuotation = (data) => {
    const url = 'ClientUsers/contactForm';
    return requestBaseMethod(url, 'POST', data);
  };
  export const sendFormFactoring = (data) => {
    const url = 'ClientUsers/factoring';
    return requestBaseMethod(url, 'POST', data);
  };
  export const sendFormCredits = (data) => {
    const url = 'ClientUsers/credits';
    return requestBaseMethod(url, 'POST', data);
  };