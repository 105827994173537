import React from 'react';
import './styles.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
// import avatar1 from '../../assets/images/avatar1.png';
// import avatar2 from '../../assets/images/avatar2.png';
// import avatar3 from '../../assets/images/avatar3.png';
import aboutImage from '../../assets/images/window.png';
import Timeline from '../../components/Timeline';

function AboutUs() {
  return (
    <section className="about__container">
      {/* SECCIÓN EQUIPO */}

      {/* <div className="about__title">
        <h1 className="about__title__main">Conoce a nuestro equipo</h1>
        <h3 className="about__title__secondary">
          Somos un equipo de amplia experiencia en el sector financiero
        </h3>
      </div> */}

      {/* <div className="about__profile-card__container">
        <article className="about__profile-card">
          <figure className="profile-card__image">
            <img src={avatar3} alt="profile avatar" />
          </figure>
          <h4 className="profile-card__title">Carlos Canales</h4>
          <p className="profile-card__subtitle">
            Socio y Presidente directorio
          </p>
          <p className="profile-card__paragraph">
            Ingeniero Civil Industrial de la Universidad Mayor (Chile), me
            he desempeñado como Gerente de distribución, Gerente
            Comercial, y Gerente de negocios financieros en compañías del
            rubro financiero y seguros, en dos geografías Chile y Perú
          </p>
          <FontAwesomeIcon
            icon={faLinkedinIn}
            size="lg"
            className="profile-card__paragraph-icon"
          />

        </article>
        <article className="about__profile-card">
          <figure className="profile-card__image">
            <img src={avatar2} alt="profile avatar" />
          </figure>
          <h4 className="profile-card__title">Matías Olivos</h4>
          <p className="profile-card__subtitle">
            Socio y Director Ejecutivo
          </p>
          <p className="profile-card__paragraph">
            Ingeniero Civil Industrial de la Universidad Mayor (Chile), me
            he desempeñado como Gerente de distribución, Gerente Comercial
            , y Gerente de negocios financieros en compañías del rubro
            financiero y seguros, en dos geografías Chile y Perú
          </p>

          <FontAwesomeIcon
            icon={faLinkedinIn}
            size="lg"
            className="profile-card__paragraph-icon"
          />
        </article>
        <article className="about__profile-card">
          <figure className="profile-card__image">
            <img src={avatar1} alt="profile avatar" />
          </figure>
          <h4 className="profile-card__title">Ricardo Ponce</h4>
          <p className="profile-card__subtitle">Socio y Gerente General</p>
          <p className="profile-card__paragraph">
            Ingeniero Civil Industrial de la Universidad Mayor (Chile), me
            he desempeñado como Gerente de distribución, Gerente
            Comercial, y Gerente de negocios financieros en compañías del
            rubro financiero y seguros, en dos geografías Chile y Perú
          </p>
          <FontAwesomeIcon
            icon={faLinkedinIn}
            size="lg"
            className="profile-card__paragraph-icon"
          />
        </article>
      </div> */}

      {/* SECCIÓN EQUIPO */}

      <div className="about__container__description">
        <h1>Quiénes somos</h1>
        <p>
          Somos un equipo de amplia experiencia en el sector asegurador
          y financiero, conocemos los diferentes productos y servicios
          que se ofrecen en el mercado y de forma ágil y expedita nos
          dedicamos a buscar las mejores alternativas para nuestros clientes.
        </p>
      </div>

      <div className="aboutUs_timeline_container">

        <Timeline />
      </div>

      {/* <div className="about__secondary-title">
            <h1>Tus preocupaciones son nuestra prioridad</h1>
            <ParagraphBanner
               text="Nuestro equipo te acompaña y respalda durante todo tú proceso"
               image={bannerImage}
            />
         </div> */}
    </section>
  );
}

export default AboutUs;
