/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { connect,useSelector  } from "react-redux";
import { getFormatedDate } from "../../utils/helpers/helpers";
import InputForm from "../InputForm";
import { useHistory } from "react-router-dom";
import "./styles.css";
import TendersTable from "../tendersTable";
import SimulationInitial from "./SimulationInitial";
import { validateTenders } from '../../utils/apis/tendersApi';
import CompanySelector from "../CompanySelector";
import { simulate } from '../../utils/apis/warrantiesApi';
import ModalLoading from "./ModalLoading";
import ModalError from "./ModalError";
import ModalSummary from "./ModalSummary";



export const options = [
  {
    value: "-1",
    nameValue: "Seriedad de oferta",
    label: "Seriedad de oferta",
  },
  {
    value: "1",
    nameValue: "Fiel cumplimiento",
    label: "Fiel cumplimiento",
  },
  {
    value: "2",
    nameValue: "Correcta ejecución",
    label: "Correcta ejecución",
  },
  {
    value: "3",
    nameValue: "Fiel cumplimiento y correcta ejecución",
    label: "Fiel cumplimiento y correcta ejecución",
  },
  {
    value: "4",
    nameValue: "Correcta ejecución y buen comportamiento de la obra",
    label: "Correcta ejecución y buen comportamiento de la obra",
  },
];


function SimpleSimulationCE({ user: { dataUser, isAuth }}) {


  const [formatedNumber, setFormatedNumber] = useState();
  const history = useHistory();
  const user = useSelector((state) => state.user.dataUser);
  const companies = useSelector((state => state.companies))
  const [todaysDate, setTodaysDate] = useState();
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [tenders, setTenders] = useState({});
  const [ response, setResponse] = useState({})


let initialState = {
  companyId: companies.defaultCompany?.CompanyId || '',
  tenderCode: tenders.Code || '',
  tenderId: tenders.res?.TenderId,
  tenderMoneyUnit: tenders.res?.Currency,
  tenderAmountString: '',
  tenderBuyerName: tenders.res?.TenderBuyer?.Name,
  tenderBuyerRut: tenders.res?.TenderBuyer?.Rut,
  tenderBuyerAddress2: tenders.res?.TenderBuyer?.UnitLocation,
  tenderBuyerAddress3: tenders.res?.TenderBuyer?.UnitArea,
  tenderbuyerAddress: tenders.res?.TenderBuyer?.UnitAddress,
  companyRut: companies.defaultCompany?.CompanyRut,
  companyName: companies.defaultCompany?.CompanyName,
  glosa: '',
  tenderWarrantyTarget: '2',
  startDate: '',
  endDate: '',
  insuranceCompanyId: 0,
  lineTypeId: 0,
  contactName: user.ClientName,
  contactEmail: user.Email,
  contactPhone: user.Phone,
  contactRut: user.Rut,
}



const [dataTenders, setDataTenders] = useState({...initialState});
const [loading, setLoading] = useState (false);
const [error, setError] = useState(false)
const [summary, setSummary] = useState(false);


console.log(dataTenders)

const inputHandler = (e) => {
  const key = e.target.name;
  const { value } = e.target;
  setDataTenders({
    ...dataTenders,
    [key]: value, 
  });


};

const inpuBlurHandler = (e) => {
  showTenderDetails(dataTenders);
}


const showTenderDetails = async (data1) => {
  const number = data1.tenderCode;
  try {
    const res = await validateTenders(number)

    setTenders({res});

    
  } catch (error) {
    console.error(error);
  }
};

useEffect(()  =>  {
setTenders(tenders)
}, []);

  const simulateWarranties = async () => {
    try {
      const res = await simulate(dataTenders);
      setLoading(true)
      const load = setTimeout(() => {
        setSummary(true)
        setLoading(false)
      }, 3000)

      setResponse(res);
      const setTenderWarrantyTarget = () => {
        switch (res.TenderWarrantyTarget) {
          case '-1':
            return 'Seriedad de oferta';
          case '1':
            return 'Fiel cumplimiento';
          case '2':
            return 'Correcta ejecución';
          case '3':
            return 'Fiel cumplimiento y correcta ejecución';
          case '4':
            return 'Correcta ejecución y buen comportamiento de la obra';
          default:
            return ' ';
        }
      };

  }catch (err) {
      setError(true)
      setLoading(false)
      console.error(err);
    }
  };


  const inputHandlerDate = ({ target: { name, value } }) => {
    setIncompleteFields((fields) => fields.filter((field) => field !== name));
    setDataTenders((d) => ({ ...d, [name]: value }));
  };


  useEffect(() => {
    const { year, month, day } = getFormatedDate();
    setTodaysDate(`${year}-${month}-${day}`);
  }, []);



  
  return (
    <div className="principal__tenders">
              {/* <div className="container__text__warrantys">
          <h5>Información de contacto</h5>
          <p>Necesitamos estos datos para darte la cotización que necesitas, 
¿Me los puedes
 compartir?</p>
        </div>
        <div className="container__simulation__form">
      <form className="simulation__form">
      {isAuth ? 
              <div className="simulation__form__inputs-container" >
                <div className="container__companies">
                <CompanySelector/>
                </div>


{summary ? <ModalSummary response={response} setSummary={setSummary} dataUser={dataUser}  /> : null}

{loading ?  <ModalLoading/> : null}
{error? <ModalError setError={setError}/> : null}

              <div className="company_form_inputs">
                <InputForm
                  type="text"
                  name="tenderCode"
                  title="Número de licitación"
                  value={dataTenders.tenderCode}
                  handlerChange={inputHandler}
                  handlerKeyDown={inpuBlurHandler}
                  className="flex_2"
        
                />
                  <InputForm
                  type="text"
                  name="contactName"
                  title="Nombre mandante"
                  value={dataTenders.contactName}
                  handlerChange={inputHandler}
                  className="flex_2"
        
                />
                <InputForm
                type="text"
                  name="Rut"
                  title="Rut"
                  value={dataTenders.contactRut}
                  handlerChange={inputHandler}
                  className="flex_2"
                />
                <InputForm
                  type="text"
                  name="glosa"
                  title="Glosa:"
                  value={dataTenders.glosa}
                  handlerChange={inputHandler}
                  className="flex_4"
                />
              </div>
              <div className="company_form_inputs">
                <InputForm
                  type="text"
                  name="Region"
                  title="Región Empresa Mandante"
                  value={companies.defaultCompany?.Region}
                  handlerChange={inputHandler}
                  className="flex_2"
        
                />
                <div className="company_form_inputs company_form_inputs--small flex_3">
                  <InputForm
                    type="text"
                    name="Comuna"
                    title="Comuna Empresa Mandante"
                    value={companies.defaultCompany?.Comuna}
                    handlerChange={inputHandler}
                    className="flex_2"
        
                  />
                  <InputForm
                    type="text"
                    name="Direccion"
                    title="Dirección Empresa Mandante"
                    value={companies.defaultCompany?.Address +" "+ companies.defaultCompany?.NumberAddress}
                    handlerChange={inputHandler}
                    className="flex_2"
           
                  />
                </div>
              </div>
              <div className="company_form_inputs">
              <InputForm
            name="tenderWarrantyTarget"
            title="Finalidad"
            value={"Correcta ejecución"}
            readOnly
          />
           <InputForm
              type="number"
              name="tenderAmountString"
              title="Monto de garantía"
              placeholder=""
              value={dataTenders.tenderAmountString}
              handlerChange={inputHandlerDate}
              error={incompleteFields.includes("tenderAmountString")}
            />
     <InputForm
              title="Fecha de inicio"
              type="date"
              min={todaysDate}
              name="startDate"
              value={dataTenders.startDate || ""}
              handlerChange={inputHandlerDate}
              error={incompleteFields.includes("startDate")}
            />
     <InputForm
              title="Fecha de termino"
              type="date"
              min={todaysDate}
              name="endDate"
              value={dataTenders.endDate || ""}
              handlerChange={inputHandlerDate}
              error={incompleteFields.includes("endDate")}
            />            
              </div>
              <div className="tenders__form__button">
          <button type="button" onClick={()=>simulateWarranties()}>Continuar</button>
        </div>
     
            </div>
  : <SimulationInitial incompleteFields={incompleteFields} formatedNumber={formatedNumber} options={options} todaysDate={todaysDate}/>} 
      </form>
      </div> */}
           {isAuth ? <div className="container__search">
        {/* <strong><p>¿Necesitas más información?</p></strong> */}
        {/* <p>Usa nuestro buscador</p> */}
        <TendersTable/>
      </div> 
     :     <div className="container__auto">
     <p>Auto atiendete</p> <p><strong><a href="/login">Iniciando Sesión</a></strong></p> 
</div>}
    </div>

  );
}

const mapStateToProps = ({ user, companies }) => ({ user, companies});

export default connect(mapStateToProps)(SimpleSimulationCE);