import "./styles.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logoWanak from "../../assets/images/logoWanak.svg"

function ModalErrorExecutive({data}) {
const history =  useHistory();

    return(
<div className="container__modal__simulation">
<div className="modal__simulation">
    <div className="contenedor__modal">
        <img src={logoWanak} alt="logo wanak"></img>
<h4>No se pudo completar tu solicitud...</h4>
<p>¡Tu ejecutivo te contactará!</p>
<div className="container__data__executive">
<span><strong>Ejecutivo:</strong> {data.ExecutiveName}</span>
<span><strong>Whatsapp:</strong> {data.ExecutivePhone}</span>
<span><strong>Email:</strong> {data.ExecutiveEmail}</span>
</div>
</div>
<div className="container__button__modalErrorExe">
<button type="button" className="btn__modalError" onClick={() => history.push("/")}>Ir al inicio</button>
</div>

</div>
</div>

    )
}

export default ModalErrorExecutive;