import "./styles.css";
import React, { useState } from "react";
import SimulationSimple from "../../components/simulationSimple/index";
import SimulationInitial from "../../components/simulationSimple/SimulationInitial";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function WarrantySimulation(user) {
  const [notCompany, setNotCompany] = useState(false);
  const history = useHistory();

  return (
    <div className="principal-warrantys">      
      <SimulationSimple />
      {/* : <p>USTED NO POSEE COMPAÑIAS ASOCIADAS, AGREGUE UNA EN EL SIGUIENTE LINK: <h3><a onClick={() => history.push("/storeCompany")}>REGISTRA TU EMPRESA AQUI</a></h3></p> */}
    </div>
  );
}

export default WarrantySimulation;
