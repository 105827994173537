import "./style.css";
import { icons } from "./data";
import handscontract from "../../../../assets/images/handscontract-webp.webp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Info() {
  const history = useHistory();

  return (
    <div className="container__info">
      <div className="div__background2">
        <h2>¿Por qué preferirnos?</h2>
        <p>
          Te ofrecemos el tipo de instrumento que puedas necesitar entre
          certificados y/o pólizas para garantizar tus licitaciones.
        </p>
        <div className="info__cards__container">
          {icons.map((icon, index) => (
            <div className="container__info__icon" key={index}>
              <div className="container__title__info">{icon.img}</div>
              <h3 className="text__our__products">{icon.subtitle}</h3>
              <h6 className="text__our__products__two">{icon.text}</h6>
            </div>
          ))}
        </div>
      </div>

      <div className="container__contact">
        <div className="container__info__info">
          <h2>¿Sigues con dudas?</h2>
          <p>
            Al comprar tus garantías con nosotros disfrutarás de una emisión{" "}
            <strong>100% online</strong> y no inmovilizarás tu capital de
            trabajo. Además te olvidarás de los trámites tediosos en la Notaría.
            <p>
              Si quieres mayor información sobre lo que hacemos{" "}
              <strong>deja tus datos y te contactaremos.</strong>
            </p>
          </p>
          <div className="container__button__contact">
            <a href="#contact">
              <button className="button__blue mb-4">Contáctanos</button>
            </a>
          </div>
        </div>

        <div className="hand__contact">
          <img src={handscontract}></img>
        </div>
      </div>

      <section className="banner__newsletter">
        <div className="container__newSletter">
          <div className="mt-2">
            <h3>¿Listo para comenzar?</h3>
            <h6>Presiona el botón y encuentra todo lo que estás buscando...</h6>
          </div>

          <button
            className="button__white mb-4"
            onClick={() => history.push("/Quoter")}
          >
            Ir al cotizador
          </button>
        </div>
      </section>
    </div>
  );
}
export default Info;
