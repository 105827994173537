import { useHistory } from 'react-router-dom';
import "./style.css";
import bussines from "../../../src/assets/images/bussines.png";
import conversation from "../../../src/assets/images/conversation.png";
import designer from "../../../src/assets/images/designer.png";
import others from "../../../src/assets/images/others.png";

export default function Insurance() {
const history = useHistory()


  const clientNeeds = [
    {
      name: "Movilidad",
      img: bussines,
      url: ("/Mobility")
    },
    {
      name: "Patrimonio",
      img: conversation,
      url: ("/Patrimony")
    },
    {
      name: "Equipos",
      img: designer,
      url: ("/Equipments")
    },
    {
      name: "Otros",
      img: others,
      url: ("/Others")
    },
  ];

  return (
    <div className="box-insurance">
      <div className="box-principal">
        <h2>¿Qué quieres cuidar?</h2>
        <p>Cotiza y contrata el seguro que necesitas</p>
      </div>
      <div className="box-text">
        <p>
          Nuestra Corredora de Seguros cuenta con capital chileno, con solvencia
         comprobada y reconocida por brindar soluciones y programas de seguros a un
            diverso portafolio.
          </p>
        <h4 className="text-end">¡Nos adaptamos a tus necesidades!</h4>
        </div>
        <div className="box-cards">
          {clientNeeds.map((client) => (
            <div className="cards">
              <h5>{client.name}</h5>
              <img
              src={client.img}
              alt={"imagen"}
            />
            <button onClick={() => history.push(client.url)} className="button-quote">Cotizar</button>
            </div>
          ))}

        </div>
    </div>
  );
}

