import React, { useState } from 'react';
import InputForm from '../../components/InputForm';
import "./style.css"
import Button from '../../components/Button';
import { recoverPassword } from '../../utils/apis/clientUsersApi';
import swal from "sweetalert"

export default function ForgotPassword () {
    const [email, setEmail] = useState('');
    console.log(email)

    const handlerInput = (event) => {
        setEmail(event.target.value);
      };

      const sendEmailForRecoverPassword = () => {
        console.log('aca estamos')
        recoverPassword(email)
          .then((res) => {
            console.log(res);
           swal('El correo ha sido enviado con exito');
      
          })
          .catch((error) => {
            console.error(error);
            swal('El correo no ha podido ser enviado. Intenta nuevamente más tarde.');
          });
      };

return(    
<section className="forgot__password__screen__container">

<form className="form__forgot__password">
  <h2 className="forgotPassword__title">Recupera tu contraseña</h2>
  <div className="forgotPassword-form__container">
  <InputForm
  className="email__forgotPassword"
      type="email"
      name="email"
      title="Ingresa tu email"
      placeholder="Email"
      handlerChange={handlerInput}
    />
  </div>
  <div  className="btn__send__forgot__password">

  <Button
  type="button"
 value="Enviar"
 clickHandler={sendEmailForRecoverPassword}
 />

  </div>
</form>
</section>)
}