import "./styles.css";
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import logoSii from "../../assets/images/logoSii.png";
import InputRut from "../../components/InputForm/InputRut";
import InputPassword from "../../components/InputForm/InputPassword";
import { saveSiiCredentials } from "../../utils/apis/clientUsersApi";
import btnClose from "../../assets/images/btnClose.svg"
import swal from "sweetalert";
import { FaRegWindowClose } from "react-icons/fa";

function ModalClave({ setClaveSii }) {
    const user = useSelector((state) => state.user);
    const { ClientId } = user.dataUser;
  const [formData, setFormData] = useState({
    clientId: ClientId,
    rut: "",
    password: "",
  });

  const handlerInput = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const CloseModal = () => {
    setClaveSii(false);
  };


const saveCredentials = async() => {

    try {
const res = await saveSiiCredentials(formData)
console.log(res)
setClaveSii(false)

 }catch(err){
   swal("No se pudo guardar su clave, intentelo nuevamente")
    setClaveSii(false)

 }
 
}
  return (
    <div className="container__modal__simulation">
      <div className="modal__clave">


        <div className="header__modal__clave">
        <img src={logoSii} alt="logoSii" className="logoSii"></img>
          <p>
         Clave Tributaria
          </p>
          <button
            type="button"
            onClick={CloseModal}
            className="btn__close__clave">
              <FaRegWindowClose className="close_sii"/>            
          </button>
        </div>
<div className="container__inputs__claveSii">
    <div className="">
    <InputRut
          formData={formData}
          setFormData={setFormData}
          name="rut"
          title="RUT"
          value={formData.rut}
          handlerchange={handlerInput}
          className="input__save__rut"
        />
    </div>



        <InputPassword
            name="password"
            title="Ingresar Clave Tributaria"
            placeholder="*********"
            value={formData.password}
            handlerChange={handlerInput}
            className="input__save__clave"
          />
</div>

        <div className="contenedor__modal__clave">
          <button type="submit" onClick={saveCredentials} className="button__save__clave">GUARDAR</button>
          <div className="container__button__modalError"></div>
        </div>
      </div>
    </div>
  );
}

export default ModalClave;
