import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import { chile } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import Button from "../../components/Button";
import lugage from "../../assets/images/lugage.svg";
import Form from "../../components/Form";

export default function CreateCompany() {
  const [formData, setFormData] = useState('');
  const [idChile, setIdChile] = useState(-1);
  const [incompleteFields, setIncompleteFields] = useState([
    "reason",
    "rutCompany",
    "giro",
    "address",
    "number",
    "officeNumber",
    "region",
    "comuna",
  ]);
console.log('faltan:',incompleteFields)
  const history = useHistory();

  const checkFormData = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      [
        "reason",
        "rutCompany",
        "giro",
        "address",
        "number",
        "officeNumber",
        "region",
        "comuna",
      ],
      formData
    );
    setIncompleteFields(incompleteFieldsArray);
  };

  console.log("formData", formData)
  const data =localStorage.getItem("data register");
  const registerForm = JSON.parse(data);

  const handlerRegion = (e) => {
    const option = e.target.value;
    setIdChile(option);
    setFormData({
      ...formData,
      region: chile.regiones[option],
    });
    localStorage.setItem("data company", JSON.stringify(formData));
    checkFormData();
  };

  const handlerComuna = (e) => {
    const option = e.target.value;
    setFormData({
      ...formData,
      comuna: chile.regiones[idChile].comunas[option],
    });
    localStorage.setItem("data company", JSON.stringify(formData));
    checkFormData();
  };
  const handlerInput = (event) => {
    const name = event.target.name;
    const lastName = event.target.lastName;
    const { value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      [lastName]: value,
    });
    localStorage.setItem("data company", JSON.stringify(formData));
    checkFormData();
  };
console.log(formData)

useEffect(() => {
},[formData])


const saveDataPerson =() => {
  const dataCompany = localStorage.getItem("data company" ,JSON.parse(dataCompany));
}

  return (
    <section className="create__container">
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard">
          <div className="stick-wizard"></div>
        </div>
      </div>

      <div className="form__container__create">
        <div className="container__title__create">
          <h2 className="create__title">COMPLETA TU REGISTRO</h2>
          <p className="create__subtitle">
            Con nosotros obtendrás lo que necesites para tu licitación de forma
            rápida y online
          </p>
        </div>
        <div className="inputs__create">
          <InputForm
            type="text"
            name="reason"
            title="Nombre/Razón Social"
            value={formData.reason}
            handlerChange={handlerInput}
          />

          <InputRut
            formData={formData}
            setFormData={setFormData}
            name="rutCompany"
            title="RUT"
            value={formData.rutCompany}
            onChange={checkFormData}
          />
          <InputForm
            type="text"
            name="giro"
            title="Giro"
            value={formData.giro}
            onChange={(event) => setFormData(event.target.value)}
            handlerChange={handlerInput}
          />
        </div>
        <div className="inputs__create__two">
          <InputForm
            type="text"
            name="phone"
            title="Celular"
            placeholder="+5699999999"
            value={registerForm.phone}
            handlerChange={handlerInput}
          />
          <InputForm
            type="text"
            name="contact"
            title="Nombre de contacto"
            value={registerForm.name + " " + registerForm.lastName}
            onChange={(event) => setFormData(event.target.value)}
            handlerChange={handlerInput}
          />
          <InputForm
            type="text"
            name="email"
            title="Email"
            placeholder="nombre@wanak.com"
            value={registerForm.email}
            handlerChange={handlerInput}
          />
        </div>
        <div className="inputs__create__three">
          <InputForm
            type="text"
            name="address"
            title="Dirección"
            onChange={(event) => setFormData(event.target.value)}
            handlerChange={handlerInput}
          />
          <InputForm
            type="number"
            name="number"
            title="Número"
            onChange={(event) => setFormData(event.target.value)}
            handlerChange={handlerInput}
            className="number__input__number"
          />
          <InputForm
            type="number"
            name="officeNumber"
            title="N° Oficina"
            onChange={(event) => setFormData(event.target.value)}
            handlerChange={handlerInput}
            className="number__input__office"
          />
          <select
            className="select-regiones"
            name="region"
            onChange={(e) => {
              handlerRegion(e);
            }}
            onClick={handlerRegion}
          >
            <option>Región</option>
            {chile.regiones.map((item, index) => (
              <option value={index}>{item.region}</option>
            ))}
          </select>
          <select
            className="select-comunas"
            name="comuna"
            onChange={(e) => {
              handlerComuna(e);
            }}
            onClick={handlerComuna}
          >
            <option>Comuna</option>
            {idChile > -1 &&
              chile.regiones[idChile].comunas.map((item, index) => (
                <option value={index}>{item}</option>
              ))}
          </select>
        </div>
        <div className="container__button__create">
          <Form
          buttonValue="CONTINUAR"
          type="step2register"
          submitButtonEnabled={incompleteFields.length == 0 ? false : true}
          >

          </Form>
        </div>
      </div>
      <div className="container-lugage">
        <img src={lugage} alt="maletin"></img>
      </div>
    </section>
  );
}