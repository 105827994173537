import requestBaseMethod from './baseApi';

export const login = (data) => {
  const url = 'ClientUsers/authenticate';
  return requestBaseMethod(url, 'POST', data);
};

export const googleLogIn = (email) => {
  const url = 'ClientUsers/googleAuthentication';
  const data = { user: email, password: '' };
  return requestBaseMethod(url, 'POST', data);
};

export const register = (data) => {
  const url = 'ClientUsers/wanakRegisterC';
  return requestBaseMethod(url, 'POST', data);
};

export const forgotPassword = (data) => {
  const url = 'Client/Home/ForgotPassword';
  return requestBaseMethod(url, 'POST', data)
}

export const sendUserValidationEmail = (email) => {
  const url = `ClientUsers/WanakSendUserValidationEmail?ToEmail=${email}`;
  return requestBaseMethod(url, 'GET');
};

export const validate = (q) => {
  const url = `ClientUsers/validate?q=${q}`;
  return requestBaseMethod(url, 'GET');
};

//obtener las empresas de los clientes
export const getMyCompanies = (id) => {
  const url = `ClientUsers/getMyCompanies?UserId=${id}`;
  return requestBaseMethod(url, 'GET');
};

export const setDefaultCompany = (userId, companyId) => {
  const url = `ClientUsers/${userId}/defaultCompany/${companyId}`;
  return requestBaseMethod(url, 'PUT');
};

export const changePassword = (userId, newPassword, newPasswordRepeated, changePasswordCode) => {
  const url = `ClientUsers/changePass/${changePasswordCode}`;
  return requestBaseMethod(url, 'PUT', {
    newPassword, newPasswordRepeated, changePasswordCode
  });
};
export const recoverPassword = (email) => { 
  const url = `ClientUsers/${email}`
  return requestBaseMethod(url, 'GET',email)
}

export const editUser = (id, data) => {
const url = `ClientUsers/WanakEditUser`;
return requestBaseMethod(url, 'PUT', id, data)
}

export const validateCoupon = (userId, couponCode) => {
  const url = `ClientUsers/${userId}/validateCoupon/${couponCode}`;
  return requestBaseMethod(url, 'POST');
};

export const dataClient = (token) => {
  const url = `ClientUsers/me`;
  return requestBaseMethod(url, 'GET',null, token)
}
export const saveSiiCredentials = (data) => {
  const url = `ClientUsers/UpdateSiiCredentials`;
  return requestBaseMethod(url, 'POST', data)
}

