/* eslint-disable no-unused-vars */
import {
  faDownload,

} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import PdfModal from '../../components/PdfModal';
import Spinner from '../../components/Spinner';
import getAceptaDocument from '../../utils/apis/aceptaApi';
import { getMyContractsFromAPI } from '../../utils/apis/warrantiesApi';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import MobileTable from '../../components/MobileTable/MobileTable';

const columnsContracts = [
  {
    title: 'Fecha',
    field: 'DateString',
    disableClick: true,
  },
  {
    title: 'Código',
    field: 'DocumentCode',
    disableClick: true,
  },
  {
    title: 'Estado',
    field: 'State',
    disableClick: true,
  },
];

const tableOptions = {
  search: false,
  showTitle: false,
  toolbar: false,
  paging: false,

};

function ContractsTable({ company }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emptyTableMessage, setEmptyTableMessage] = useState(null);
  const [width] = useWindowDimentions();

  const getContract = async ({ DocumentCode }) => {
    console.log(`Console de Contracts Table : ${DocumentCode}`);
    try {
      setLoading(true);
      const res = await getAceptaDocument(DocumentCode);
      setLoading(false);
      setPdfData(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const documentIcon = () => (
    !loading ? (
      <FontAwesomeIcon
        icon={faDownload}
        color="#0048a2"
        style={{ fontSize: 16 }}
      />
    )
      : <Spinner />
  );

  useEffect(async () => {
    setTableData([]);
    setEmptyTableMessage(null);
    try {
      const res = await getMyContractsFromAPI(company.CompanyId);
      if (res.length === 0) {
        setEmptyTableMessage('Su empresa no posee contratos.');
      }
      setTableData(res);
    } catch (error) {
      console.log(error);
    }
  }, [company]);
  return (
    <div>
      {width > 500
        ? (
          <DataTable
            emptyMessage={emptyTableMessage}
            data={tableData}
            columns={columnsContracts}
            options={tableOptions}
            stylesContainer={{ width: 'auto', margin: 'auto' }}
            actions={[{
              icon: () => documentIcon(),
              tooltip: 'Verificar Contrato',
              onClick: (event, row) => getContract(row),
            }]}
          />
        )
        : (
          <MobileTable
            data={tableData}
            columns={columnsContracts}
            options={tableOptions}
            emptyMessage={emptyTableMessage}
            stylesContainer={{ width: 'auto', margin: 'auto' }}
            actions={[{
              icon: () => documentIcon(loading),
              onClick: getContract,
              round: true,
            },
            ]}
            headers={['DocumentCode']}
          />
        )}
      {showModal && <PdfModal pdfData={pdfData} setShowModal={setShowModal} />}
    </div>
  );
}

export default ContractsTable;
