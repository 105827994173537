import React from 'react';
import './styles.css';

function InputSelect({
  name,
  title,
  options,
  styles,
  handlerChange,
  error,
  className,
  defaultValue,
}) {
  return (
    <label className={`input__container ${className || ''} ${error ? 'input_error' : ''}`}>
      {title && (
      <p className="input__title">
        {title}
      </p>
      )}
      <select
        name={name}
        style={styles ? { ...styles } : null}
        onChange={handlerChange}
      >
        <option selected hidden disabled value="">{defaultValue || 'Selecciona tu empresa...'}</option>
        {options.map((item, index) => (
          <option key={`key${index}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </label>
  );
}

export default InputSelect;
