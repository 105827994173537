import React from 'react';
import InputForm from '../../InputForm';
import SquareButton from '../../SquareButton';

function StepTwo({
  contactName,
  contactEmail,
  contactPhone,
  next,
  goBack,
}) {
  const nextStep = () => {
    if (contactName && contactEmail && contactPhone) {
      next();
    }
  };

  return (
    <div className="step_container">
      <div className="step_content">
        <h2
          className="step_content_title"
        >
          Selecciona tu empresa
        </h2>
        <InputForm
          type="text"
          name="contactName"
          title="Nombre"
          value={contactName}
          readOnly
        />
        <InputForm
          type="text"
          name="contactEmail"
          title="Email"
          value={contactEmail}
          readOnly
        />
        <InputForm
          type="text"
          name="contactPhone"
          title="Teléfono"
          value={contactPhone}
          readOnly
        />
      </div>
      <div
        className="step_actions"
      >
        <SquareButton
          value="Atrás"
          type="text"
          clickHandler={() => goBack()}
          styles={{
            backgroundColor: 'transparent',
          }}
        />
        <SquareButton
          value="Siguiente"
          type="text"
          clickHandler={nextStep}
        />
      </div>
    </div>
  );
}

export default StepTwo;
