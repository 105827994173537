import "./styles.css";
import logoWanak from "../../assets/images/logoWanak.svg"
import Spinner from "../Spinner";


function ModalLoading() {
    return(
<div className="container__modal__simulation">
<div className="modal__simulation">
    <div className="contenedor__modal">

        <img src={logoWanak} alt="logo wanak"></img>
<h3>¡Estas a un paso más cerca!</h3>
<p>Danos unos segundos... </p>
<Spinner/>
</div>
</div>
</div>

    )
}

export default  ModalLoading;