import React from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createNotification } from "../../utils/helpers/helpers";
import InputForm from "../../components/InputForm";
import { editUser } from "../../utils/apis/clientUsersApi";
import { setUserEdit } from "../../store/actions/userActions";
import { dataClient } from "../../utils/apis/clientUsersApi";


 function ModalEditUser({
  formData,
  setFormData,
  setShowModal,
  props

}) {


const history = useHistory();

  const closeModal = () => {
    setShowModal(false);
  };

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const userEdit = async (event) => {
    event.preventDefault();
    const id = formData.ClientId;
    try {
    await editUser(formData, id);
    const res = await dataClient(props.user.token)
props.setUserEdit(res)
      setShowModal(false)
      createNotification("success", null, "Datos guardados con éxito", 3000);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div className="Modal__background">
      <div className="form__background">
        <div className="company_form_inputs">
          <InputForm
            type="text"
            name="ClientName"
            title="Nombre"
            placeholder="Nombre"
            value={formData.ClientName}
            handlerChange={inputHandler}
            className="flex_4"
          />
          <InputForm
            type="text"
            name="Phone"
            title="Teléfono"
            placeholder="+56999999999"
            value={formData.Phone}
            handlerChange={inputHandler}
            className="flex_3"
          />
        </div>
        <div className="container__buttons__modalEdit">
          <button className="btn__modal__edit" onClick={closeModal}>
            Cancelar
          </button>
          <button className="btn__modal__edit" onClick={userEdit}>
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  setUserEdit: (payload) => dispatch(setUserEdit(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditUser);