import "./styles.css";
import logoWanak from "../../assets/images/logoWanak.svg"
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function ModalSendRequest({setCloseModal}) {

const history = useHistory();
    const closeSendResquest = () => {
        setCloseModal(false)
        history.push("/")
    }



    return(
<div className="container__modal__simulation">
<div className="modal__simulation">
    <div className="contenedor__modal">
    <div className="container__button__modalError">
        <button className="btn__close" type="button" onClick={() => closeSendResquest()}>Cerrar</button>
        </div>
        <img src={logoWanak} alt="logo wanak"></img>
<h3>¡Tú solicitud fue enviada con éxito!</h3>
<p>Nos pondremos en contacto contigo</p>
</div>
</div>
</div>

    )
}

export default  ModalSendRequest;