import { NotificationManager } from 'react-notifications';
import { createWebPayTransactionApi } from '../apis/transbankApi';
import { getShoppingCart, getTotalPrice } from '../apis/warrantiesApi';

// eslint-disable-next-line import/prefer-default-export
export const getItemsAndTotalPrice = async (userId) => {
  try {
    const warranties = await getShoppingCart(userId);
    console.log('Warranties: ', warranties);
    const price = await getTotalPrice(userId);
    if (price) return { warranties, price };
    return null;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateShoppingCart = async (ClientId, addToCart) => {
  try {
    const {
      warranties,
      price: { ShoppingCartTotal, ShoppingCartTotalString },
    } = await getItemsAndTotalPrice(ClientId);
    const payload = { warranties, ShoppingCartTotal, ShoppingCartTotalString };
    await addToCart(payload);
  } catch (e) {
    console.log('error en update shopping cart');
  }
};

export const createNotification = (
  type,
  message,
  title = null,
  timeOut = 10000,
  callback = null,
) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message, title, timeOut, callback);
      break;
    case 'success':
      NotificationManager.success(message, title, timeOut, callback);
      break;
    case 'warning':
      NotificationManager.warning(message, title, timeOut, callback);
      break;
    case 'error':
      NotificationManager.error(message, title, timeOut, callback);
      break;
    default:
  }
};

// Retorna un array con los nombres de los campos que estan vacios.
export const checkIncompleteFields = (requiredFields, formData) => {
  const incompleteArray = [];
  requiredFields.forEach((field) => {
      if (formData[field] === null || formData[field] === '' || formData[field] === undefined || !formData[field]) {
        incompleteArray.push(field);
      }
  });
  return incompleteArray;
};

export const getFormatedDate = (dateToFormat = null) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const isoFormatDate = date.toISOString();
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  const year = date.getFullYear();
  return {
    day, month, year, isoFormatDate,
  };
};

export const createWebPayTransaction = async (
  ClientId,
  warrantiesToBuy,
  ammount,
  coupon = null,
) => {
  const data = {
    companyUserId: ClientId,
    warrantiesIds: warrantiesToBuy.map(({ WarrantyId }) => WarrantyId),
    amount: ammount,
    couponClientId: coupon,
  };
  try {
    const response = await createWebPayTransactionApi(data);
    return response;
  } catch (error) {
    return { message: 'Ha ocurrido un error al crear la solicitud de pago.' };
  }
};

// export const checkEmptyInputs=()=>{

// }
