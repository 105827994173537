import React, { useState } from "react";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import "./style.css";
// import { dataOptions } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import { sendFormCredits } from "../../utils/apis/quotation";
import { useHistory } from "react-router-dom";
import { createNotification } from "../../utils/helpers/helpers";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { dataOptions } from "../Factoring/data";

export default function Credit() {
  const history = useHistory();
  const [formData, setFormData] = useState({ ...dataOptions });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const handlerOnChange = (value) => {
    console.log("captcha value", value);
  };

  //VALIDANDO BOTÓN DE ENVIAR
  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["firstName", "rutProveedor", "email", "phone"],
      formData
    );
    console.log("campos vacios", incompleteFieldsArray);
    if (incompleteFieldsArray.length == 0) setSubmitButtonEnabled(false);
    else setSubmitButtonEnabled(true);
  };

  //  //HOOK DE EFECTO PARA VALIDAR BUTTON Y QUE SE INICIALICE EN LA DATA
  useEffect(() => {
    validateButton();
  }, [formData]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendFormCredits(formData);
      console.log("esto se envió", formData);
      history.push("/infoReceived");
    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
    }
  };

  return (
    <div className="container-credits">
      <div className="me-5 capital_trabajo">
        <h3>Capital de Trabajo para empresas</h3>
        <p>
          El financiamiento para <strong>Capital de Trabajo InGe! </strong>
          es un apoyo monetario que podrás solicitar, asesorado por nosotros,
          sin dejar algún bien como respaldo. Te asesoramos para que el proceso
          de solicitud sea exitoso y así alcances el objetivo que tanto deseas.
          Somos expertos en el tema y contamos con un grupo de partners que se
          encargan de entregar los fondos necesarios para este financiamiento.
        </p>
        <h3>¿Por qué solicitar Capital de Trabajo InGe?</h3>
        <p>
          <ol>
            <li>Estabilizas el flujo de efectivo de tu empresa</li>
            <li>
              No requiere <strong>garantías</strong>
            </li>
            <li>Liquidez inmediata</li>
            <li>Sin cobros por adelantado</li>
            <li>Asesoría de expertos en el tema</li>
          </ol>
        </p>
        <p>
          Con nuestra gestión podrás alcanzar el financiamiento desde el 30%
          hasta el 50% del promedio de tus últimos seis meses de venta (con un
          tope máximo de $50.000.000 millones de pesos).
        </p>
        <p className="">
          <strong>Contáctanos</strong> y recibe más información sobre esta alternativa de
          financiamiento que te dará la liquidez inmediata que necesitas.
        </p>
      </div>
      <section className="credits__forms">
        <form className="form-factoring-credits float-end">
          <GoogleReCaptchaProvider reCaptchaKey="6LdW_9khAAAAAEGx6saxoqhGp0rqrJAGrGRJVUuo">
            <div className="form-security__form">
              <div className="form-security__inputs">
                <div className="info-contact">
                  <h5>Información de Contacto</h5>
                  <p>
                    Necesitamos estos datos para darte la cotización que
                    necesitas, ¿me los puedes Compartir?
                  </p>
                </div>
                <InputForm
                  type="text"
                  value={formData.firstName}
                  name="firstName"
                  title="Nombre Completo*"
                  placeholder="Maria"
                  handlerChange={inputHandler}
                  error={incompleteFields.includes("firstName")}
                />
                <InputRut
                  formData={formData}
                  setFormData={setFormData}
                  name="rutProveedor"
                  title="Rut Empresa*"
                  value={formData.rutProveedor}
                  className="input__rut__factoring"
                />
                <InputForm
                  type="email"
                  name="email"
                  title="Email*"
                  value={formData.email}
                  placeholder="nombre@wanak.com"
                  handlerChange={inputHandler}
                  error={incompleteFields.includes("email")}
                />
                <InputForm
                  type="number"
                  value={formData.phone}
                  name="phone"
                  title="Teléfono Móvil*"
                  placeholder="+5699999999"
                  handlerChange={inputHandler}
                  error={incompleteFields.includes("phone")}
                />
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn_global_wanak_gray"
                  onClick={submitHandler}
                  disabled={submitButtonEnabled}
                >
                  Cotizar
                </button>
              </div>
            </div>
          </GoogleReCaptchaProvider>
        </form>
      </section>
    </div>
  );
}
