/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import ModalTemplate from '../ModalTemplate';
import './styles.css';

const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  const bytesArray = bytes.map((byte, index) => binaryString.charCodeAt(index));
  return bytesArray;
};

function PdfModal({ setShowModal, pdfData }) {
  const [iframeURL, setIframeURL] = useState('');

  useEffect(() => {
    const bytesArray = base64ToArrayBuffer(pdfData);
    const file = new Blob([bytesArray], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    setIframeURL(fileURL);
  }, [pdfData]);

  return (
    <ModalTemplate closeModal={() => setShowModal(false)} styles={{ width: '90%', maxWidth: 'none', maxHeight: '90vh' }}>
      <iframe className="pdfModal_iframe" src={iframeURL} frameBorder="0" />
    </ModalTemplate>
  );
}

export default PdfModal;
