/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  getFormatedDate,
  checkIncompleteFields,
} from "../../utils/helpers/helpers";
import InputForm from "../InputForm";
import { useHistory } from "react-router-dom";
import "./styles.css";
import TendersTable from "../tendersTable";
import SimulationInitial from "./SimulationInitial";
import { validateTenders } from "../../utils/apis/tendersApi";
import CompanySelector from "../CompanySelector";
import { simulate } from "../../utils/apis/warrantiesApi";
import ModalLoading from "./ModalLoading";
import ModalError from "./ModalError";
import ModalSummary from "./ModalSummary";
import InputSelect from "../InputSelect";

const requiredFields = [
  "tenderCode",
  "tenderBuyerName",
  "tenderBuyerRut",
  "glosa",
  "tenderBuyerAddress3",
  "tenderBuyerAddress2",
  "tenderbuyerAddress",
  "tenderWarrantyTarget",
  "tenderAmountString",
  "startDate",
  "endDate",
];

export const options = [
  {
    value: "-1",
    nameValue: "Seriedad de oferta",
    label: "Seriedad de oferta",
  },
  {
    value: "1",
    nameValue: "Fiel cumplimiento",
    label: "Fiel cumplimiento",
  },
  {
    value: "2",
    nameValue: "Correcta ejecución",
    label: "Correcta ejecución",
  },
  {
    value: "3",
    nameValue: "Fiel cumplimiento y correcta ejecución",
    label: "Fiel cumplimiento y correcta ejecución",
  },
  {
    value: "4",
    nameValue: "Correcta ejecución y buen comportamiento de la obra",
    label: "Correcta ejecución y buen comportamiento de la obra",
  },
];

function SimpleSimulationSO({ user: { dataUser, isAuth }, warrantyType }) {
  const [formatedNumber, setFormatedNumber] = useState();
  const user = useSelector((state) => state.user.dataUser);
  const userCompanies = useSelector((state) => state.companies);
  const [todaysDate, setTodaysDate] = useState();
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [tenders, setTenders] = useState({});
  const [response, setResponse] = useState({});

  let initialState = {
    companyId: userCompanies.defaultCompany?.CompanyId,
    tenderId: tenders.res?.TenderId,
    tenderMoneyUnit: tenders.res?.Currency,
    tenderAmountString: "",
    tenderBuyerName: tenders.res?.TenderBuyer?.Name,
    tenderBuyerRut: tenders.res?.TenderBuyer?.Rut,
    tenderBuyerAddress2: tenders.res?.TenderBuyer?.UnitLocation,
    tenderBuyerAddress3: tenders.res?.TenderBuyer?.UnitArea,
    tenderbuyerAddress: tenders.res?.TenderBuyer?.UnitAddress,
    companyRut: userCompanies.defaultCompany?.CompanyRut,
    companyName: userCompanies.defaultCompany?.CompanyName,
    glosa: "",
    tenderWarrantyTarget: warrantyType,
    startDate: "",
    endDate: "",
    insuranceCompanyId: 0,
    lineTypeId: 0,
    contactName: user.ClientName,
    contactEmail: user.Email,
    contactPhone: user.Phone,
    contactRut: user.Rut,
  };

  let warrantyTypeName = options.find(
    (w) => w.value === warrantyType
  ).nameValue;

  const [dataTenders, setDataTenders] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [summary, setSummary] = useState(false);

  console.log(dataTenders);

  const inputHandler = (e) => {
    const key = e.target.name;
    const { value } = e.target;

    setIncompleteFields(incompleteFields.filter((field) => field !== key));

    setDataTenders({
      ...dataTenders,
      [key]: value,
    });

    if (key === "companyId") {
      const selectedCompany = userCompanies.companies.find(
        (c) => c.CompanyId === parseInt(value)
      );
      setDataTenders({
        ...dataTenders,
        companyRut: selectedCompany.CompanyRut,
        companyName: selectedCompany.CompanyName,
      });
    }
  };

  const inpuBlurHandler = (e) => {
    showTenderDetails(dataTenders);
  };

  const userCompaniesOptions = () =>
    userCompanies.companies.map((company) => ({
      value: company.CompanyId,
      label: company.CompanyName,
    }));

  const showTenderDetails = async (data1) => {
    const number = data1.tenderCode;
    try {
      const res = await validateTenders(number);

      setTenders({ res });
      setDataTenders({
        ...dataTenders,
        tenderBuyerName: res?.TenderBuyer?.Name ?? "",
        tenderBuyerRut: res?.TenderBuyer?.Rut ?? "",
        tenderbuyerAddress: res?.TenderBuyer?.UnitAddress ?? "",
        tenderBuyerAddress2: res?.TenderBuyer?.UnitLocation ?? "",
        tenderBuyerAddress3: res?.TenderBuyer?.UnitArea ?? "",
      });
    } catch (error) {
      setDataTenders({
        ...dataTenders,
        tenderBuyerName: "",
        tenderBuyerRut: "",
        tenderbuyerAddress: "",
        tenderBuyerAddress2: "",
        tenderBuyerAddress3: "",
      });
    }
  };

  useEffect(() => {
    setTenders(tenders);
  }, []);

  const simulateWarranties = async () => {
    const incompleteFieldsArray = checkIncompleteFields(
      requiredFields,
      dataTenders
    );
    setIncompleteFields(incompleteFieldsArray);
    if (incompleteFieldsArray.length > 0) {
      return;
    }

    try {
      const res = await simulate(dataTenders);
      setLoading(true);
      const load = setTimeout(() => {
        setSummary(true);
        setLoading(false);
      }, 3000);

      setResponse(res);
      const setTenderWarrantyTarget = () => {
        switch (res.TenderWarrantyTarget) {
          case "-1":
            return "Seriedad de oferta";
          case "1":
            return "Fiel cumplimiento";
          case "2":
            return "Correcta ejecución";
          case "3":
            return "Fiel cumplimiento y correcta ejecución";
          case "4":
            return "Correcta ejecución y buen comportamiento de la obra";
          default:
            return " ";
        }
      };
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error(err);
    }
  };

  const inputHandlerDate = ({ target: { name, value } }) => {
    setIncompleteFields((fields) => fields.filter((field) => field !== name));
    setDataTenders((d) => ({ ...d, [name]: value }));
  };

  useEffect(() => {
    const { year, month, day } = getFormatedDate();
    setTodaysDate(`${year}-${month}-${day}`);
  }, []);

  return (
    <div className="principal__tenders">
      <div className="container__text__warrantys">
        <h5>Información de contacto</h5>
        <p>
          Necesitamos estos datos para darte la cotización que necesitas, ¿Me
          los puedes compartir?
        </p>
      </div>
      <div className="container__simulation__form">
        <form className="simulation__form">
          {isAuth ? (
            <div className="simulation__form__inputs-container">
              <div className="container__companies">
                {/* <CompanySelector/>  */}
                <InputSelect
                  name="companyId"
                  options={userCompaniesOptions()}
                  defaultValue={
                    userCompanies.defaultCompany &&
                    userCompanies.defaultCompany.CompanyName
                  }
                  handlerChange={inputHandler}
                />
              </div>

              {summary ? (
                <ModalSummary
                  response={response}
                  setSummary={setSummary}
                  dataUser={dataUser}
                />
              ) : null}

              {loading ? <ModalLoading /> : null}
              {error ? <ModalError setError={setError} /> : null}

              <div className="company_form_inputs">
                <InputForm
                  type="text"
                  name="tenderCode"
                  title="Número de licitación"
                  value={dataTenders?.tenderCode}
                  handlerChange={inputHandler}
                  handlerKeyDown={inpuBlurHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderCode")}
                />
                <InputForm
                  type="text"
                  name="tenderBuyerName"
                  title="Nombre mandante"
                  value={dataTenders?.tenderBuyerName}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerName")}
                />
                <InputForm
                  type="text"
                  name="tenderBuyerRut"
                  title="Rut"
                  value={dataTenders?.tenderBuyerRut}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerRut")}
                />
                <InputForm
                  type="text"
                  name="glosa"
                  title="Glosa:"
                  value={dataTenders?.glosa}
                  handlerChange={inputHandler}
                  className="flex_4"
                  error={incompleteFields.includes("glosa")}
                />
              </div>

              <div className="company_form_inputs">
                <InputForm
                  type="text"
                  name="tenderBuyerAddress3"
                  title="Región Empresa Mandante"
                  value={dataTenders?.tenderBuyerAddress3}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerAddress3")}
                />
                <div className="company_form_inputs company_form_inputs--small flex_3">
                  <InputForm
                    type="text"
                    name="tenderBuyerAddress2"
                    title="Comuna Empresa Mandante"
                    value={dataTenders?.tenderBuyerAddress2}
                    handlerChange={inputHandler}
                    className="flex_2"
                    error={incompleteFields.includes("tenderBuyerAddress2")}
                  />
                  <InputForm
                    type="text"
                    name="tenderbuyerAddress"
                    title="Dirección Empresa Mandante"
                    value={dataTenders?.tenderbuyerAddress}
                    handlerChange={inputHandler}
                    className="flex_2"
                    error={incompleteFields.includes("tenderbuyerAddress")}
                  />
                </div>
              </div>
              <div className="company_form_inputs">
                <InputForm
                  name="tenderWarrantyTarget"
                  title="Finalidad"
                  value={warrantyTypeName}
                  readOnly
                />
                <InputForm
                  type="number"
                  name="tenderAmountString"
                  title="Monto de garantía"
                  placeholder=""
                  value={dataTenders?.tenderAmountString}
                  handlerChange={inputHandlerDate}
                  error={incompleteFields.includes("tenderAmountString")}
                />
                <InputForm
                  title="Fecha de inicio"
                  type="date"
                  min={todaysDate}
                  name="startDate"
                  value={dataTenders.startDate || ""}
                  handlerChange={inputHandlerDate}
                  error={incompleteFields.includes("startDate")}
                />
                <InputForm
                  title="Fecha de termino"
                  type="date"
                  min={todaysDate}
                  name="endDate"
                  value={dataTenders.endDate || ""}
                  handlerChange={inputHandlerDate}
                  error={incompleteFields.includes("endDate")}
                />
              </div>
              <div className="w-100">
                <button
                  className="button__blue mt-4 float-end"
                  type="button"
                  onClick={() => simulateWarranties()}
                >
                  Continuar
                </button>
              </div>
              <div className="tenders__form__button"></div>
            </div>
          ) : (
            <SimulationInitial
              incompleteFields={incompleteFields}
              formatedNumber={formatedNumber}
              options={options}
              todaysDate={todaysDate}
            />
          )}
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user, companies }) => ({ user, companies });

export default connect(mapStateToProps)(SimpleSimulationSO);
