import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import InputForm from '.';

function InputPassword({
  title, placeholder, handlerChange, styles, error, className, value, name,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const eyeIcon = () => (
    <FontAwesomeIcon
      icon={showPassword ? faEyeSlash : faEye}
      onClick={() => setShowPassword(!showPassword)}
      style={{ color: ` ${showPassword ? 'gray' : 'var(--secondary-color)'}` }}
    />
  );
  return (
    <InputForm
      type={showPassword ? 'text' : 'password'}
      title={title}
      value={value}
      placeholder={placeholder}
      handlerChange={handlerChange}
      styles={styles}
      error={error}
      className={className}
      icon={eyeIcon()}
      name={name}
    />
  );
}

export default InputPassword;
