import React, { useEffect, useState } from "react";
import InputForm from "../../../../components/InputForm";
import Button from "../../../../components/Button";
import "./styles.css";
import InputSelect from "../../../../components/InputSelect";
import DateInputs from "../../../../components/DateInputs";
import CaseCotizacion from "./CaseCotizacion";
import {
  checkIncompleteFields,
  createNotification,
} from "../../../../utils/helpers/helpers";
import InputRut from "../../../../components/InputForm/InputRut";
import { sendContactForm } from "../../../../utils/apis/companiesApi";

let newDate = new Date();

const initialState = {
  firstName: "",
  lastName: "-",
  rut: "",
  email: "",
  phone: "",
  message: "",
  createdAt: newDate.toISOString(),
  factura: "-",
  rutProveedor: "-",
  rutReceptor: "-",
  warrantyTarget: "",
  warrantyId: "",
  tenderAmount: "",
  startDate: "",
  endDate: "",
};

const initialOption = "Elija una opción...";

const options = [
  {
    value: "licitación privada",
    label: "Licitación Privada / Trato Directo",
  },
  {
    value: "solicitud de cobro",
    label: "Solicitud de cobro de garantía",
  },
  {
    value: "sugerencia",
    label: "Reclamos / Felicitaciones / Sugerencias",
  },
  {
    value: "cotizacion",
    label: "Solicitud de cotización",
  },
];

const initialRequiredFields = ["firstName", "phone", "email", "rut", "message"];

function Contact() {
  const [formData, setFormData] = useState({ ...initialState });
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [requiredFields, setRequiredFields] = useState([
    ...initialRequiredFields,
  ]);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    /// ////////////////////////////////////////////////////
    // Primero se saca el input en el que se está escribiendo de los campos incompletos
    setIncompleteFields(
      incompleteFields.filter((field) => field !== event.target.name)
    );
    /// ///////////////////////////////////////////
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    const incompleteFieldsArray = checkIncompleteFields(
      requiredFields,
      formData
    );
    setIncompleteFields(incompleteFieldsArray);
    event.preventDefault();
    if (incompleteFieldsArray.length === 0) {
      console.log(formData);
      try {
        await sendContactForm(formData);
        createNotification(
          "success",
          "Nos pondremos en contacto con usted.",
          "Su formulario ha sido enviado con éxito"
        );
      } catch (error) {
        createNotification(
          "error",
          "Por favor intentelo más tarde.",
          "Ha ocurrido un error al enviar su formulario"
        );
      }
      //vuelve los inputs a los estados normales
      setFormData(initialState);
      setSelectedOption(initialOption);
    }
  };

  const commentTextArea = (placeholder) => (
    <textarea
      name="message"
      className={`${incompleteFields.includes("message") ? "input_error" : ""}`}
      placeholder={placeholder}
      value={formData.message}
      onChange={inputHandler}
    />
  );
  const { tenderAmount, warrantyTarget, startDate, endDate, warrantyId } =
    formData;
  //Renderiza los formularios para cada accion que se requiera
  useEffect(() => {
    setIncompleteFields([]);
    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      rut: formData.rut,
      rutProveedor: formData.rutProveedor,
      rutReceptor: formData.rutReceptor,
      factura: formData.factura,
      createdAt: formData.createdAt,
      email: formData.email,
      phone: formData.phone,
      tenderAmount: formData.tenderAmount,
      warrantyId: formData.warrantyId,
      warrantyTarget: formData.warrantyTarget,
      message: formData.message,
    });
    setRequiredFields([...initialRequiredFields]);
    if (selectedOption === "cotizacion") {
      setRequiredFields([...requiredFields, "warrantyId", "warrantyTarget"]);
    }
  }, [selectedOption]);

  useEffect(() => {
    setIncompleteFields([]);
    if (selectedOption === "licitación privada") {
      setFormData({
        ...formData,
        message: `(Licitacion Privada / Trato Directo) Monto de licitacion : ${formData.tenderAmount} / Fecha :  ${formData.startDate} - ${formData.endDate}`,
        tenderAmount: formData.tenderAmount,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
      setRequiredFields([...initialRequiredFields]);
      if (selectedOption === "cotizacion") {
        setRequiredFields([...requiredFields, "warrantyId", "warrantyTarget"]);
      }
    } else {
      setFormData({
        ...formData,
        tenderAmount: formData.tenderAmount,
        message: "",
      });
    }
  }, [tenderAmount, selectedOption, startDate, endDate]);

  useEffect(() => {
    setIncompleteFields([]);
    if (selectedOption === "cotizacion") {
      setFormData({
        ...formData,
        message:`${formData.message}`,
        rutProveedor: `${formData.warrantyTarget}`,
        rutReceptor: `${formData.warrantyId}`
      });
      console.log(formData.message.length);

      setRequiredFields([...initialRequiredFields]);
      if (selectedOption === "cotizacion") {
        setRequiredFields([...requiredFields, "warrantyId", "warrantyTarget"]);
      }
    } else {
      setFormData({
        ...formData,
        message: "",
      });
    }
  }, [warrantyId, warrantyTarget]);

  console.log(formData);
  return (
    <section className="contact__container" id="contact">
      <form className="form-contact" onSubmit={submitHandler}>
        <div className="form-contact__text">
          <h2>Hola, ¿Necesitas ayuda?</h2>
        </div>
        <div className="form-contact__form">
          <div className="form-contact__inputs">
            <InputForm
              type="text"
              name="firstName"
              title="Nombre y Apellido"
              placeholder="José Perez"
              value={formData.firstName}
              handlerChange={inputHandler}
              error={incompleteFields.includes("firstName")}
            />
            <InputForm
              type="number"
              name="phone"
              title="Teléfono Móvil"
              placeholder="+5699999999"
              value={formData.phone}
              handlerChange={inputHandler}
              error={incompleteFields.includes("phone")}
            />
            <InputForm
              type="email"
              name="email"
              title="Email"
              placeholder="nombre@wanak.com"
              value={formData.email}
              handlerChange={inputHandler}
              error={incompleteFields.includes("email")}
            />
            <InputRut
              formData={formData}
              setFormData={setFormData}
              name="rut"
              title="Rut o Pasaporte"
              className="input__rut__contact"
              error={incompleteFields.includes("rut")}
            />
          </div>
          <div className="form-contact__optional_inputs">
            <span className="input__title form_contact_second_title">
              Cuéntanos, en qué podemos ayudarte
            </span>
            <div className="form_contact_select">
              <InputSelect
                name="optionValue"
                value={selectedOption}
                options={options}
                handlerChange={({ target: { value } }) =>
                  setSelectedOption(value)
                }
                defaultValue="Elija una opción..."
              />
            </div>
            {selectedOption === "licitación privada" && (
              <div className="case_licitacionPrivada_container">
                <InputForm
                  type="number"
                  name="tenderAmount"
                  title="Monto de Licitación"
                  placeholder="$99.999.999"
                  value={formData.tenderAmount}
                  handlerChange={inputHandler}
                  error={incompleteFields.includes("rutProveedor")}
                />
                <DateInputs formData={formData} setFormData={setFormData} />
              </div>
            )}
            {selectedOption === "solicitud de cobro" &&
              commentTextArea("Solicitud de cobro...")}
            {selectedOption === "sugerencia" &&
              commentTextArea(
                "Escriba aqui su felicitación, reclamo o sugerencia..."
              )}
            {selectedOption === "cotizacion" && (
              <CaseCotizacion
                formData={formData}
                setFormData={setFormData}
                incompleteFields={incompleteFields}
                commentTextArea={commentTextArea}
              />
            )}
          </div>
        </div>
        <div className="form-contact__button">
          <button className="button__gray" type="submit" value="Enviar">
              Enviar
          </button>
          
        </div>
      </form>
    </section>
  );
}

export default Contact;
