/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { HelpOutline, Info, InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function DropdownMenu({ options }) {
  const selectDescription = (index) => {
    if (index === showDescription) {
      return -1;
    } return index;
  };
  const [showDescription, setShowDescription] = useState(-1);
  return (
    <ul className="nav__link__dropdownMenu">
      {options.map(({ label, path, description }, index) => (
        <li className="dropdown_menu_link " key={index}>
          {' '}
          <div className="link_container" onClick={() => setShowDescription(selectDescription(index))} role="button" tabIndex={0}>
            <Link className={`link ${path !== '' && 'link_hoverable'}`} to={path}>
              {label}
            </Link>
            { description && <InfoOutlined className="description_toggle" fontSize="small" />}
          </div>
          <div className={`dropdown_menu_link_description_container ${showDescription === index ? 'description_open' : ''}`}>
            <p className="description">
              {description}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default DropdownMenu;
