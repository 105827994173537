import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import NewsletterFormModal from './NewsletterFormModal';
import './styles.css';

function Newsletter() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="newsletter_container">
      {/* <div className="newsletter_title_container">
        <FontAwesomeIcon
          icon={faFileAlt}
        />
        <h1>Inscribete a nuestro newsletter!</h1>
      </div> */}
      <button onClick={() => setShowModal(true)}>
        <FontAwesomeIcon
          icon={faFileAlt}
        />
        Newsletter
      </button>
      {showModal && <NewsletterFormModal closeModal={() => setShowModal(false)} />}

    </div>
  );
}

export default Newsletter;
