import "./style.css"
import pencil from "../../assets/images/pencil.svg"
export default function Notifications(){
    return(<div className="screen__container shoppingCart__container">
<p className="text__principal__notification">ESTAMOS TRABAJANDO PARA DARTE UNA MEJOR EXPERIENCIA!</p>
<div className="container__image__notifications">
<img src={pencil}></img>
</div>

    </div>)
}