import {
  SET_CART, REMOVE_FROM_CART, RESET_CART, SET_LOADING,
} from '../constants/shoppingCartConstants';

export const addToCart = (payload) => ({
  type: SET_CART,
  payload: { ...payload },
});

export const removeFromCart = (payload) => ({
  type: REMOVE_FROM_CART,
  payload,
});
export const resetCart = () => ({
  type: RESET_CART,
});
export const setLoadingShoppingCartInStore = (payload) => ({
  type: SET_LOADING,
  payload,
});
