import React, { useState } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputForm from '../../components/InputForm';
import SquareButton from '../../components/SquareButton';
import { storeCompany } from '../../utils/apis/companiesApi';
import { setCompanies } from '../../store/actions/companiesActions';
import { getMyCompanies } from '../../utils/apis/clientUsersApi';
import CompanyForm from '../../components/CompanyForm';
import { checkIncompleteFields, createNotification } from '../../utils/helpers/helpers';

const initialState = {
  rut: '',
  name: '',
  phone: '',
  address: '',
  observations: '',
  isValidated: true,
  location: '',
  region: '',
  giro: '',
  addressNumber: '',
  addressOffice: '',
  email: '',
  userId: 0,
};



const RequiredFields = ['name', 'rut', 'giro', 'address', 'phone', 'email', 'location', 'region', 'addressNumber'];

function StoreCompany(props) {
  const [formData, setFormData] = useState({ ...initialState });
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handlerSubmit = async (event) => {
    event.preventDefault();
    const incompleteFieldsArray = checkIncompleteFields(RequiredFields, formData);
    setIncompleteFields(incompleteFieldsArray);
    if (incompleteFieldsArray.length === 0) {
      const userId = props.user.dataUser.ClientId;
      const data = {
        ...formData,
        userId,
      };
      try {
        setLoading(true);
        console.log('first')
        await storeCompany(data);
        console.log('second')
        const clientCompanies = await getMyCompanies(userId);
        console.log('3')
        createNotification('success', 'Compañia guardada con éxito', formData.name, 1000);
        props.setCompanies(clientCompanies);
        setFormData({ ...initialState });
        
        setTimeout(() => history.push('/editCompany'), 2000);
      } catch (error) {
        console.log(error);
        createNotification('error', 'Por favor intente nuevamente', 'Ha ocurrido un error', 1000);
      }
      setLoading(false);
    }
  };

  return (
   
      <section className="container__storeCompany">
      <h1 className="title__storeCompany" style={{ margin: '3rem 0' }}>Crear nueva empresa</h1>
      <form className="store-company__form" onSubmit={handlerSubmit}>
        <h2
          className="store-company__form__title"
          style={{ marginLeft: 15 }}
        >
          Ingresa los datos de tu empresa
        </h2>
        <CompanyForm
          formData={formData}
          setFormData={setFormData}
          incompleteFields={incompleteFields}
          setIncompleteFields={setIncompleteFields}
        />
        <SquareButton
          value="Crear empresa"
          type="submit"         
          loading={loading}
        />
      </form>
      </section>
  

  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanies: (payload) => dispatch(setCompanies(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreCompany);
