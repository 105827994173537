import React, { useEffect } from "react";
import "./styles.css";
import { Link, NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import InfoDisplay from "./InfoDisplay";
import DropdownMenu from "./DropdownMenu";
import useScrollPosition from "../../utils/helpers/Hooks/useScrollPosition";
import chile from "../../assets/images/chile.png";
import Sidebar from "./Sidebar";

function Header({
  menuOptions: {
    conocenosOptions,
    nuestrosProductosOptions,
    userOptions,
    userOptionsWithCompany,
  },
  ...props
}) {
  const { isAuth } = props.user;
  const { companies } = props.companies;
  const history = useHistory();

  const scrollPosition = useScrollPosition();

  return (
    <header
      className={`header ${
        scrollPosition > 3 ? "header__container--scrolled" : ""
      } `}
    >
      <div className="header__container">
        <nav className="header__nav">
          <div
            className="header__logo"
            onClick={() => history.push("/")}
            role="button"
            tabIndex={0}
          />
          <ul className="nav__link-container">
            <li className="nav__link">
              <NavLink className={ ({isActive}) => `link ${ isActive ? 'active':'' }` } to="/Quoter">
                Cotizador
              </NavLink>
            </li>
            <li className="nav__link">
              <NavLink className={ ({isActive}) => `link ${ isActive ? 'active':'' }` } to="/tenders">
                Buscador de Licitaciones
              </NavLink>
            </li>

            <li className="nav__link">
              <NavLink className={ ({isActive}) => `link ${ isActive ? 'active':'' }` } to="/checkWarranty">
                Verificador
              </NavLink>
            </li>
            <li className="nav__link">
              <div className="link-sobre me-2">Sobre Nosotros</div>
              <FontAwesomeIcon
                icon={faChevronDown}
                color="blue"
                style={{ fontSize: "10px", color: "#0166ff6e" }}
              />
              <DropdownMenu options={conocenosOptions} />
            </li>
          </ul>

          {isAuth ? <Sidebar {...props} /> : null}
        </nav>
      </div>
    </header>
  );
}

export default Header;
