/* eslint-disable react/no-unstable-nested-components */

import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from '../DataTable';
import TableItem from './TableItem';

const tableOptions = {
  search: false,
  showTitle: false,
  toolbar: false,
  paging: false,
  actionsColumnIndex: -1,

};

// headers es el parametro que se mostrará en la tabla, todo el resto se mostrará en el colapsable.
function MobileTable({
  data, columns, options, styles, actions, headers, rowClickHandler, emptyMessage, innerRef,
}) {
  const mobileDataFields = () => columns.filter((column) => !headers.includes(column.field));

  const showDataIcon = () => (
    <FontAwesomeIcon
      icon={faChevronCircleDown}
      color="#0048a2"
      style={{ fontSize: 18 }}
    />
  );

  return (

    <DataTable
      innerRef={innerRef}
      data={data}
      columns={columns.filter(({ field }) => headers.includes(field))}
      options={{ tableOptions, ...options }}
      rowClickHandler={rowClickHandler}
      emptyMessage={emptyMessage}
      stylesContainer={{ width: 'auto', margin: 'auto', ...styles }}
      detailPanel={[
        {
          icon: () => showDataIcon(),
          render: (rowData) => (
            <TableItem
              item={rowData}
              mobileDataFields={mobileDataFields()}
              actions={actions}
            />
          ),
        }]}
    />

  );
}

export default MobileTable;
