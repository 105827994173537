import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./styles.css";
import Explain from "./components/Explain";
import Collaborators from "./components/Collaborators";
// import Services from './components/Services';
import Chart from "./components/Chart";
import Contact from "./components/Contact";
import CarouselInitial from "./components/Carousel/Carousel";
import Info from "./components/Info";
import Blog from "./components/Blog";
import Banner from "./components/Banner";

function Home(props) {
  useEffect(() => {}, [props.user, props.companies]);

  return (
    <div className="home__container">
      <CarouselInitial />
      <Explain {...props} />
      <Collaborators />
      <Info />
      <Blog />
      <Chart />
      <Contact />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  companies: state.companies,
  shoppingCart: state.shoppingCart,
});

export default connect(mapStateToProps)(Home);
