import React from 'react';
import Spinner from '../Spinner';
import './styles.css';

function InputForm({
  type,
  name,
  title,
  value,
  placeholder,
  handlerChange,
  handlerKeyDown,
  styles,
  readOnly,
  error,
  min,
  className,
  icon,
  loading,
  children,
  defaultValue,
}) {
  // useEffect(() => {
  //   console.log('error changes');
  // }, [error]);
  return (
    <label className={`input__container ${className || ''} ${error && 'input_error'}`}>
      {title && (
      <p className="input__title">
        {title}
        {loading && <Spinner styles={{ border: '#c7c1c1 3px solid', width: '0.7em', height: '0.7em' }} />}
      </p>
      )}
      <div className="input_text_container">
        <input
          className="input"
          style={styles ? { ...styles } : null}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handlerChange}
          readOnly={readOnly}
          onBlur={handlerKeyDown}
          min={min}
          step="any"
          defaultValue={defaultValue}
        />
        {icon && (
        <div className="input_icon_container">
          {icon}
        </div>
        )}
        {children}
      </div>
    </label>
  );
}

export default InputForm;
