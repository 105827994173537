import React from 'react';
import './styles.css';
import InputForm from '../InputForm';
import InputRut from '../InputForm/InputRut';

function CompanyForm({
  formData, setFormData, incompleteFields, setIncompleteFields,
}) {
  const inputHandler = (event) => {
    /// /////////////////////////////////////////////////////
    // Primero se saca el input en el que se está escribiendo de los campos incompletos.
    /// /////////////////////////////////////////////////////
    setIncompleteFields(incompleteFields.filter((field) => field !== event.target.name));
    /// ///////////////////////////////////////////
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  // Formulario de creación de empresa.

  return (
    <div className="store-company__form__inputs-container">
      <div className="company_form_inputs">
        <InputForm
          type="text"
          placeholder="Viveros Terranova"
          name="name"
          title="Nombre/Razón Social:"
          value={formData.name}
          handlerChange={inputHandler}
          className="flex_4"
          error={incompleteFields.includes('name')}
        />
        <InputRut
          name="rut"
          title="Rut:"
          setFormData={setFormData}
          formData={formData}
          className="flex_2"
          error={incompleteFields.includes('rut')}
        />
        <InputForm
          type="text"
          placeholder="Giro de su empresa"
          name="giro"
          title="Giro:"
          value={formData.giro}
          handlerChange={inputHandler}
          className="flex_6"
          error={incompleteFields.includes('giro')}
        />
      </div>
      <div className="company_form_inputs">
        <InputForm
          type="text"
          placeholder="Direccion de la empresa"
          name="address"
          title="Dirección:"
          value={formData.address}
          handlerChange={inputHandler}
          className="flex_6"
          error={incompleteFields.includes('address')}
        />
        <div className="company_form_inputs company_form_inputs--small flex_3">
          <InputForm
            type="text"
            placeholder="123"
            name="addressNumber"
            title="Numero:"
            value={formData.addressNumber}
            className="flex_3"
            handlerChange={inputHandler}
            error={incompleteFields.includes('addressNumber')}

          />
          <InputForm
            type="text"
            placeholder="Oficina 123"
            name="addressOffice"
            title="Oficina:"
            value={formData.addressOffice}
            className="flex_3"
            handlerChange={inputHandler}

          />
        </div>
      </div>
      <div className="company_form_inputs">
        <InputForm
          type="text"
          placeholder="Region Metropolitana"
          name="region"
          title="Región:"
          value={formData.region}
          handlerChange={inputHandler}
          className="flex_3"
          error={incompleteFields.includes('region')}
        />
        <InputForm
          type="text"
          placeholder="Providencia"
          name="location"
          title="Comuna:"
          value={formData.location}
          handlerChange={inputHandler}
          className="flex_3"
          error={incompleteFields.includes('location')}
        />
        {/* </div> */}
        {/* <div className="company_form_inputs"> */}

        <InputForm
          type="text"
          placeholder="99999999"
          name="phone"
          title="Teléfono"
          value={formData.phone}
          className="flex_3"
          handlerChange={inputHandler}
          error={incompleteFields.includes('phone')}
        />
        <InputForm
          type="text"
          placeholder="mail@empresa.cl"
          name="email"
          title="Email:"
          value={formData.email}
          className="flex_3"
          handlerChange={inputHandler}
          error={incompleteFields.includes('email')}
        />
      </div>

    </div>
  );
}

export default CompanyForm;
