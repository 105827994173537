export const initialState = {
    Rut: "",
    Name: "",
    Phone: "",
    Address: "",
    Observations: "",
    IsValidated: true,
    DataOriginId: 0,
    AssignedExecutiveId: 0,
    Location: "",
    Region: "",
    Giro: "",
    AddressNumber: "",
    AddressOffice: "",
    Email: "",
    Linea: 0,
    LineaAprobada: 0,
    LineaUsada: 0,
    LineaDisponible: 0,
    LineaAprobadaUF: 0,
    LineaUsadaUF: 0,
    CompanyId: 0,
    UserId: 0,
  };