import React from 'react';
import './styles.css';

function WhatsappButton() {
  return (
    <button
      className="whatsapp-button-styles"
      onClick={() => window.open('https://acortar.link/skr90b')}
    />
  );
}

export default WhatsappButton;
